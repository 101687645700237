import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button
  } from '@chakra-ui/react'
import React from 'react'

function WarningModal({title,isOpen,onClose,updating,onConfirm,btnColor, btnTitle,desc,loadingText}:any) {
    return (
      <>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay
    >
          <ModalContent>
                        <ModalHeader>{ title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                            <Text>{ desc}</Text>
            </ModalBody>
            <ModalFooter>
               <Button onClick={onClose}>Close</Button>
                            <Button ml={'10px'} loadingText={loadingText} isLoading={updating? true: false} colorScheme={btnColor} onClick={onConfirm}>{ btnTitle}</Button>
            </ModalFooter>
          </ModalContent>
                </ModalOverlay>
                </Modal>
      </>
    )
}
  
export default WarningModal;
import { useEffect, useState } from "react";
import { CiCircleQuestion, CiSearch } from "react-icons/ci";
import {
  Flex,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  useToast,
  Spinner,
  Box,
  Center,
  Heading,
    Button,
    FormControl,
    FormLabel,
    Select,
    Textarea,
    Table,
  Tbody,
  Td,
  Th,
  Thead,
    Tr,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    VStack
} from "@chakra-ui/react";
import SelectComponent from 'react-select';
import { findAll, save } from "../../apis/serviceApis";
import { BsTrash } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import Requests from "./Requests";
import Services from "./Services";
import AskService from "./AskService";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

const OtherServices = () => {
  const toast = useToast();
  
  const [decodedHeader,setDecodedHeader] = useState<any>({});

  useEffect(() => {
    try {
      const token:any = window.localStorage.getItem("access_token");
      setDecodedHeader(jwt_decode(token))
    }
    catch (error: any) {
      
    }
    },[])

    const navigate = useNavigate();
    const [settingTabIndex,setTabIndex] = useState<number>(0);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
  const TAB_TITLES = ['Requests', 'Services','Ask service'];
      
      useEffect(() => {
        const tab: any = searchParams.get("tab");
        if ((parseInt(tab) >= 0) == false) {
          return navigate("/services?tab=0")
        }
        setTabIndex(parseInt(tab));
      }, [searchParams]);
    
    const onChangeTab = (index: number) => {
      navigate(`/services?tab=${index}`);
    }

  return (
    <>
     {
      decodedHeader?.role=="ADMIN" || decodedHeader?.role == "SUPER_ADMIN" ?
      <VStack px="30px" pb="10px" pt={'105px'} >
      <Tabs variant="soft-rounded" w="100%" index={settingTabIndex} onChange={onChangeTab}>
        <TabList justifyContent={'center'}>
          {TAB_TITLES.map((title, idx) => (
            <Tab key={`tab-${idx}`}>{title}</Tab>
          ))}
        </TabList>
        <TabPanels>
        <TabPanel>
         <Requests/>
          </TabPanel>
          <TabPanel>
            <Services />
                          </TabPanel>
                          <TabPanel>
            <AskService />
          </TabPanel>
        </TabPanels>
      </Tabs>
      </VStack>
        :
        <VStack w={'100%'} px="30px" pb="10px" pt={'105px'} >
           <Box w={'100%'}> <AskService /></Box>
        </VStack>
      }
    </>
    )
}

export default OtherServices;
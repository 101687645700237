import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  useToast,
  Spinner,
  Box,
  Center,
  Heading,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  FormControl,
  FormLabel,
  Image,
  Tbody,
  Td,
  Table,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BiUser, BiUserPlus } from "react-icons/bi";
import {
  addAnAdmin,
  changeProfile,
  changeUserPassword,
  getAdmins,
} from "../../apis/usersApis";
import jwt_decode from "jwt-decode";
import { RiLockPasswordLine } from "react-icons/ri";
import useAuth from "../../apis/useAuth";
import { BsCamera } from "react-icons/bs";

const Settings = () => {
  const toast = useToast();
  const [decodedHeader, setDecodedHeader] = useState<any>({});
  const { logout, userInfo, getLoggedInUser }: any = useAuth();

  useEffect(() => {
    getLoggedInUser();
  }, []);

  useEffect(() => {
    const token: any = window.localStorage.getItem("access_token");
    setDecodedHeader(jwt_decode(token));
  }, []);
  const [updating, setUpdating] = useState<Boolean>(false);
  const [formData, setFormData] = useState<any>({
    email: "",
  });

  const [formData3, setFormData3] = useState<any>({
    username: "",
    photo: "",
    publicId: "",
    phone: undefined,
  });

  useEffect(() => {
    if (userInfo) {
      setFormData3({
        username: userInfo.username,
        photo: userInfo?.photo,
        publicId: userInfo?.publicId,
        phone: userInfo?.phone,
      });
    }
  }, [userInfo]);

  const [updating2, setUpdating2] = useState<Boolean>(false);
  const [updating3, setUpdating3] = useState<Boolean>(false);

  const [formData2, setFormData2] = useState<any>({
    currentPassword: "",
    newPassword: "",
  });

  const addAdmin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setUpdating(true);
      const res: any = await addAnAdmin(formData);
      setUpdating(false);

      if (res?.data?.status == true) {
        setFormData({ email: "" });
        toast({
          title: "Success",
          description: res?.data?.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error: any) {
      setUpdating(false);
      toast({
        title: "Failed",
        description: error?.response?.data?.message || error?.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const changePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setUpdating2(true);
      const res: any = await changeUserPassword(formData2);
      setUpdating2(false);

      if (res?.data?.status == true) {
        setFormData2({ currentPassword: "", newPassword: "" });
        toast({
          title: "Success",
          description: res?.data?.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        logout();
      }
    } catch (error: any) {
      setUpdating2(false);
      toast({
        title: "Failed",
        description: error?.response?.data?.message || error?.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const changeAccountInfo = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setUpdating3(true);
      let imageUpdates = {
        photo: formData3?.photo,
        publicId: formData3?.publicId,
      };
      if (userInfo?.photo != formData3?.photo) {
        const CLOUDINARY_URL =
          "https://api.cloudinary.com/v1_1/duldhdjsj/image/upload";
        let data = {
          file: formData3?.photo,
          upload_preset: "s1ef0r8m",
        };

        const r = await fetch(CLOUDINARY_URL, {
          body: JSON.stringify(data),
          headers: {
            "content-type": "application/json",
          },
          method: "POST",
        });

        let imageInfo = await r.json();
        imageUpdates = {
          photo: imageInfo?.url,
          publicId: imageInfo?.public_id,
        };
        setFormData3({
          ...formData3,
          photo: imageInfo?.url,
          publicId: imageInfo?.public_id,
        });
      }

      const res: any = await changeProfile({ ...formData3, ...imageUpdates });
      setUpdating3(false);

      if (res?.data?.status == true) {
        window.localStorage.setItem("access_token", res?.data?.access_token);
        getLoggedInUser();
        const toggleUserInfo: any = document.getElementById("toggleUserInfo");
        if (toggleUserInfo) {
          toggleUserInfo.click();
        }
        toast({
          title: "Success",
          description: res?.data?.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error: any) {
      setUpdating3(false);
      toast({
        title: "Failed",
        description: error?.response?.data?.message || error?.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const loadFile = function (event: any) {
    const reader = new FileReader();
    let file = event.target.files[0];
    reader.addEventListener(
      "load",
      () => {
        setFormData3({ ...formData3, photo: reader.result });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const [admins, setAdmins] = useState<any>([]);

  const getAllAdmins = async () => {
    const res: any = await getAdmins();
    setAdmins(res?.data?.admins);
  };

  useEffect(() => {
    getAllAdmins();
  }, []);

  return (
    <React.Fragment>
      <Box pt={"30px"}>
        <Box mb={"20px"} className="table-nav">
          <Box className="tb-title">
            <Text>Settings</Text>
          </Box>
        </Box>

        <Box mb={"40px"} bg={"white"} rounded={"xl"} w={"full"}>
          <Accordion allowToggle>
            <AccordionItem
              boxShadow={"0 2px 14px #c6c6c680"}
              rounded={"2xl"}
              border={"10px solid neutral.300"}
            >
              <h2>
                <AccordionButton
                  borderTopEndRadius={"2xl"}
                  borderTopLeftRadius={"2xl"}
                  py={"14px"}
                  px={"18px"}
                  columnGap={"15px"}
                  cursor={"pointer"}
                  _hover={{ bg: "#16330014" }}
                  borderBottom={"1px solid neutral.200"}
                  _expanded={{ bg: "#16330014" }}
                >
                  <Button
                    bg={"#16330014"}
                    rounded={"full"}
                    px={"15px"}
                    py={"27px"}
                  >
                    <BiUser size={26} />
                  </Button>
                  <Box as="span" flex="1" textAlign="left">
                    <Box fontSize={"18px"}>Profile</Box>
                    <Box
                      fontWeight={"normal"}
                      fontSize={"17px"}
                      color={"text.light"}
                      as="p"
                      flex="1"
                      textAlign="left"
                    >
                      Change account information
                    </Box>
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel py={"14px"} px={"22px"}>
                <Box onSubmit={changeAccountInfo} as={"form"} py={"10px"}>
                  <Box mb={"10px"}>
                    <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                      Upload profile photo
                    </FormLabel>
                    <Center>
                      <Input
                        accept="image/*"
                        onChange={loadFile}
                        type="file"
                        style={{ display: "none" }}
                        id={"toggleFile"}
                      />
                      <Box h={"250px"} w={"250px"} position={"relative"}>
                        <Image
                          border={"3px solid #0e54dc"}
                          borderRadius={"50%"}
                          h={"100%"}
                          w={"100%"}
                          objectFit={"cover"}
                          src={formData3?.photo}
                        />
                        <button
                          type={"button"}
                          onClick={() => {
                            const file: any =
                              document.getElementById("toggleFile");
                            if (file) {
                              file.click();
                            }
                          }}
                          className="uploadFileButton"
                        >
                          <BsCamera size={20}></BsCamera>
                        </button>
                      </Box>
                    </Center>
                  </Box>
                  <FormControl mb={"10px"} isRequired>
                    <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                      Phone number
                    </FormLabel>
                    <Input
                      onChange={(e: any) => {
                        setFormData3({ ...formData3, phone: e.target.value });
                      }}
                      py={"20px"}
                      type={"number"}
                      value={formData3?.phone}
                      placeholder="+250"
                    />
                  </FormControl>
                  <FormControl mb={"16px"} isRequired>
                    <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                      Username
                    </FormLabel>
                    <Input
                      onChange={(e: any) => {
                        setFormData3({
                          ...formData3,
                          username: e.target.value,
                        });
                      }}
                      py={"20px"}
                      type={"text"}
                      value={formData3?.username}
                      placeholder="Enter new username"
                    />
                  </FormControl>

                  <Box>
                    <Button
                      loadingText={"Adding admin..."}
                      isLoading={updating3 == true ? true : false}
                      type={"submit"}
                      colorScheme="standard"
                    >
                      Save changes
                    </Button>
                  </Box>
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem
              boxShadow={"0 2px 14px #c6c6c680"}
              rounded={"2xl"}
              border={"10px solid neutral.300"}
            >
              <h2>
                <AccordionButton
                  borderTopEndRadius={"2xl"}
                  borderTopLeftRadius={"2xl"}
                  py={"14px"}
                  px={"18px"}
                  columnGap={"15px"}
                  cursor={"pointer"}
                  _hover={{ bg: "#16330014" }}
                  borderBottom={"1px solid neutral.200"}
                  _expanded={{ bg: "#16330014" }}
                >
                  <Button
                    bg={"#16330014"}
                    rounded={"full"}
                    px={"15px"}
                    py={"27px"}
                  >
                    <BiUser size={26} />
                  </Button>
                  <Box as="span" flex="1" textAlign="left">
                    <Box fontSize={"18px"}>Admins</Box>
                    <Box
                      fontWeight={"normal"}
                      fontSize={"17px"}
                      color={"text.light"}
                      as="p"
                      flex="1"
                      textAlign="left"
                    >
                      Manage admins
                    </Box>
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel py={"14px"} px={"22px"}>
                {admins?.length > 0 ? (
                  <Table>
                    <Thead>
                      <Th>Admin</Th>
                      <Th>Email</Th>
                      <Th>Phone</Th>
                      <Th>Action</Th>
                    </Thead>
                    <Tbody>
                      {admins?.map((admin: any) => {
                        return (
                          <Tr>
                            <Td>
                              <Flex columnGap={"7px"}>
                                <Box
                                  h={"40px"}
                                  w={"40px"}
                                  position={"relative"}
                                >
                                  <Image
                                    border={"1px solid #0e54dc"}
                                    borderRadius={"50%"}
                                    h={"100%"}
                                    w={"100%"}
                                    objectFit={"cover"}
                                    src={admin?.photo}
                                  />
                                </Box>

                                <Center>
                                  {" "}
                                  <Text>{admin?.username}</Text>
                                </Center>
                              </Flex>
                            </Td>
                            <Td>{admin?.email}</Td>
                            <Td>{admin?.phone}</Td>
                            <Td>
                              {decodedHeader?.role == "SUPER_ADMIN" && (
                                <Button colorScheme="danger">Delete</Button>
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                ) : (
                  <>
                    <Center p={"10px"} w={"100%"}>
                      <Text>No admins found</Text>
                    </Center>
                  </>
                )}
              </AccordionPanel>
            </AccordionItem>

            {decodedHeader?.role == "SUPER_ADMIN" && (
              <>
                <AccordionItem
                  boxShadow={"0 2px 14px #c6c6c680"}
                  rounded={"2xl"}
                  border={"10px solid neutral.300"}
                >
                  <h2>
                    <AccordionButton
                      borderTopEndRadius={"2xl"}
                      borderTopLeftRadius={"2xl"}
                      py={"14px"}
                      px={"18px"}
                      columnGap={"15px"}
                      cursor={"pointer"}
                      _hover={{ bg: "#16330014" }}
                      borderBottom={"1px solid neutral.200"}
                      _expanded={{ bg: "#16330014" }}
                    >
                      <Button
                        bg={"#16330014"}
                        rounded={"full"}
                        px={"15px"}
                        py={"27px"}
                      >
                        <BiUserPlus size={26} />
                      </Button>
                      <Box as="span" flex="1" textAlign="left">
                        <Box fontSize={"18px"}>Add lariqueza admin</Box>
                        <Box
                          fontSize={"15px"}
                          fontWeight={"normal"}
                          color={"text.light"}
                          as="p"
                          flex="1"
                          textAlign="left"
                        >
                          As super admin you can add admin by his/her email
                        </Box>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel py={"14px"} px={"22px"}>
                    <Box onSubmit={addAdmin} as={"form"} py={"10px"}>
                      <FormControl mb={"15px"} isRequired>
                        <FormLabel
                          fontWeight="medium"
                          fontSize="14px"
                          mb={"2px"}
                        >
                          Enter user email
                        </FormLabel>
                        <Input
                          onChange={(e: any) => {
                            setFormData({ ...formData, email: e.target.value });
                          }}
                          py={"20px"}
                          type={"email"}
                          value={formData?.email}
                          placeholder="Enter user email"
                        />
                      </FormControl>

                      <Box>
                        <Button
                          loadingText={"Adding admin..."}
                          isLoading={updating == true ? true : false}
                          type={"submit"}
                          colorScheme="standard"
                        >
                          Add admin
                        </Button>
                      </Box>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </>
            )}

            <AccordionItem
              boxShadow={"0 2px 14px #c6c6c680"}
              rounded={"2xl"}
              border={"10px solid neutral.300"}
            >
              <h2>
                <AccordionButton
                  borderTopEndRadius={"2xl"}
                  borderTopLeftRadius={"2xl"}
                  py={"14px"}
                  px={"18px"}
                  columnGap={"15px"}
                  cursor={"pointer"}
                  _hover={{ bg: "#16330014" }}
                  borderBottom={"1px solid neutral.200"}
                  _expanded={{ bg: "#16330014" }}
                >
                  <Button
                    bg={"#16330014"}
                    rounded={"full"}
                    px={"15px"}
                    py={"27px"}
                  >
                    <RiLockPasswordLine size={26} />
                  </Button>
                  <Box as="span" flex="1" textAlign="left">
                    <Box fontSize={"18px"}> Change password</Box>
                    <Box
                      fontWeight={"normal"}
                      fontSize={"17px"}
                      color={"text.light"}
                      as="p"
                      flex="1"
                      textAlign="left"
                    >
                      *********
                    </Box>
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel py={"14px"} px={"22px"}>
                <Box onSubmit={changePassword} as={"form"} py={"10px"}>
                  <FormControl mb={"12px"} isRequired>
                    <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                      Current Password
                    </FormLabel>
                    <Input
                      onChange={(e: any) => {
                        setFormData2({
                          ...formData2,
                          currentPassword: e.target.value,
                        });
                      }}
                      py={"20px"}
                      type={"password"}
                      value={formData2?.currentPassword}
                      placeholder="Enter current password"
                    />
                  </FormControl>

                  <FormControl mb={"20px"} isRequired>
                    <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                      New Password
                    </FormLabel>
                    <Input
                      onChange={(e: any) => {
                        setFormData2({
                          ...formData2,
                          newPassword: e.target.value,
                        });
                      }}
                      py={"20px"}
                      type={"password"}
                      value={formData2?.newPassword}
                      placeholder="Enter current password"
                    />
                  </FormControl>

                  <Box>
                    <Button
                      loadingText={"Adding admin..."}
                      isLoading={updating2 == true ? true : false}
                      type={"submit"}
                      colorScheme="standard"
                    >
                      Change Password
                    </Button>
                  </Box>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Settings;

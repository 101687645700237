import { useEffect, useState } from "react";
import { CiCircleQuestion, CiSearch } from "react-icons/ci";
import {
  Flex,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  useToast,
  Spinner,
  Box,
  Center,
  Heading,
  Button,
  MenuItem,
} from "@chakra-ui/react";
import SelectComponent from 'react-select';
import { findAll, findAllRequests, setServiceRequestStatus } from "../../apis/serviceApis";
import { BsTrash } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import RequestsTable from "./RequestsTable";
import { useLocation, useNavigate } from "react-router-dom";
import { BiFilterAlt } from "react-icons/bi";
import WarningModal from "../../components/WarningModal";

const Requests = () => {
  const toast = useToast();

  const [loadingRequests, setLoadingRequests] = useState<Boolean>(true);
  const [requests, setRequests] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [pageNum, setPageNum] = useState<number>(0);
  const navigate = useNavigate();
  const [status, setStatus] = useState<string>("PENDING");
  const [tab, setTab] = useState<number>(0);

    const getRequests = async (page:number,status:string) => {
      setLoadingRequests(true);
      let res: any = await findAllRequests(10, page, status);
      setLoadingRequests(false);
      setRequests(res.data.data || [])
      setTotal(res.data.totalElements)
    }
  
  useEffect(() => {
    const page: any = searchParams.get("page");
    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    const tab: any = searchParams.get("tab");
    setTab(parseInt(tab))
    if (parseInt(tab) == 0) {
      return navigate("/services?tab=0&page=1");
}
  }, [searchParams]);

  useEffect(() => {
    if (pageNum > 0 && status) {
      getRequests(pageNum,status);
    }
  }, [pageNum,status,tab]);

  const headers: any = [
        {
          name: "Service",
      },
      {
        name: "Names",
      },
    {
      name: "Contact email",
    },
    {
      name: "Contact phone",
      },
      {
        name: "Date",
        },
      {
        name: "Action",
      }
  ];

  const [updating, setUpdating] = useState<Boolean>(false);

  const updateStatus = async (id: any, statuses: any) => {
    try{
      setUpdating(true);
    const res:any = await setServiceRequestStatus(id, { status: statuses })
      setUpdating(false);
      
      if (res?.data?.status == true) {
        setIsOpen(false);
        toast({
            title: "Success",
            description: res?.data?.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
        });
      }
      getRequests(pageNum,status);
    }
    catch (error: any) {
      setUpdating(false);
      toast({
        title: "Failed",
        description: error?.response?.data?.message || error?.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
    });
    }
  }
  
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const [title, setTittle] = useState<string>('');
  const [currentId, setCurrentId] = useState<any>(undefined);
  const [action, setAction] = useState<string>('');
  
    return (
      <Box pt={'30px'}>
    
      <Box className="table-nav">
<Box className="tb-title">
  <Text>{ status?.toLocaleUpperCase()} requests</Text>
</Box>

<Box className="flex">
  <Center position={'relative'} left={'30px'} className="flex">
    <Input
      bg="#ffff"
      borderColor="#ffff"
      placeholder="Search..."
      colorScheme="primary"
      type={"text"}
      boxShadow="xs"
      className="search-field"
    />
    <Text className={"search-icon"}>
      <CiSearch size={20} />
    </Text>
    </Center>
    
    <Menu>
                    <MenuButton
                      as={Button}
                      aria-label="Options"
                      className={"custom_dropdown_button"}
        variant="outline"
      >
                    <Button
    colorScheme="primary"
          fontSize={"15px"}
          position={'relative'} left={'15px'}
    fontWeight={400}
    borderRadius={"10px"}
  >
    <BiFilterAlt size={18} color={"white"}></BiFilterAlt>
    &nbsp;Filter
  </Button>
        </MenuButton>
                    <MenuList
                      boxShadow="xl"
                      rounded="md"
                      className="menuListContainer menuListContainer_nopad"
                    >
        <MenuItem onClick={() => {
          setStatus("PENDING")
          navigate("/services?tab=0&page=1");
                      }} className="menu-list menu-list-edit">
                        {" "}
                        {/* <Box mr={'2px'} className="dropdown-icon">
                          <MdEdit size={18}></MdEdit>
                          {"   "}
                        </Box> */}
                      Pending requests
        </MenuItem>

        <MenuItem
          onClick={() => {
          setStatus("APPROVED")
            navigate("/services?tab=0&page=1");
                        }}
          className="menu-list menu-list-edit">
                        {" "}
                        {/* <Box mr={'2px'} className="dropdown-icon">
                          <MdEdit size={18}></MdEdit>
                          {"   "}
                        </Box> */}
                      Approved requests
        </MenuItem>

        <MenuItem
             onClick={() => {
              setStatus("REJECTED")
                navigate("/services?tab=0&page=1");
                            }}
          className="menu-list menu-list-edit">
                        {" "}
                        {/* <Box mr={'2px'} className="dropdown-icon">
                          <MdEdit size={18}></MdEdit>
                          {"   "}
                        </Box> */}
                      Rejected requests
        </MenuItem>
        
      </MenuList>
      </Menu>
</Box>
</Box>
      
      <Box
className="customers-table-container w-full"
marginBottom={"40px"}
        >
          
        <RequestsTable
          headers={headers}
          data={requests}
          loading={loadingRequests}
          totalNum={total}
        pageNum={pageNum}
        itemsPerPage={10}
            searching={false}
            reject={(id:string) => {
              setCurrentId(id);
              setIsOpen(true);
              setAction("reject")
              setTittle("Reject request");
            }}
            approve={(id:string) => {
              setCurrentId(id);
              setIsOpen(true); 
              setAction("approve")
              setTittle("Approve request");
            }}
        ></RequestsTable>
            
        </Box>

        <WarningModal
          title={title}
          isOpen={isOpen}
          onConfirm={() => {
              updateStatus(currentId,action=="reject"? "REJECTED" : "APPROVED");
          }}
          updating={updating}
          btnTitle={action == "reject" ? "Reject" : "Approve"}
          btnColor={action == "reject"? "danger" : "success"}
          loadingText={action == "reject" ? "Rejecting..." : "Approving..."}
          desc={`Are you sure you want to ${action} this request?`}
          onClose={() => {
            setIsOpen(false);
          }}
        />

        </Box>
    )
}

export default Requests;
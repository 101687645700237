import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Center,
    Box,
    Image
  } from '@chakra-ui/react'
import React, { useEffect } from 'react'

function CameraModal({onClose,isOpen,openImages}:any) {
    const videoElem: any = React.useRef();
    const [cameraStream,setStream] = React.useState<any>(undefined)

    const openCamera = async() => {
        const stream: any = await navigator?.mediaDevices?.getUserMedia({ video: true })
        if (videoElem?.current && stream) {
            videoElem.current.srcObject = stream;
        }
        setStream(stream)
    }

    useEffect(() => {
        if (isOpen == true) {
            openCamera()
        }
    }, [isOpen])

    const [images,setImages]  =React.useState<any>([])
    
    const takePhoto = () => {
        const canvas: any = document.getElementById('canvas');
        canvas.width = videoElem.current.videoWidth;
        canvas.height = videoElem.current.videoHeight;
        canvas.getContext('2d').drawImage(videoElem.current, 0, 0, canvas.width, canvas.height);
        const imageDataURL = canvas.toDataURL('image/png');
        if (images?.length < 7) {
            setImages([imageDataURL,...images])
        }
    }

    const closeCamera = () => {
        if (cameraStream) {
            cameraStream.getTracks().forEach((track:any) => {
                track.stop();
            });
            if (videoElem.current) {
                videoElem.current.srcObject = null;
            }
        }
    }
  
    return (
      <>
            <Modal onClose={() => {
                closeCamera();
                setImages([])
                onClose();
            }} size={'full'} isOpen={isOpen}>
                
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Take property picture</ModalHeader>
            <ModalCloseButton />
                    <ModalBody>
                        <Center>
                            <Box>
                            <canvas style={{display: "none"}} id="canvas"></canvas>
                                <video height={'100%'} ref={videoElem} autoPlay={true}></video>
                                <Center columnGap={'15px'}>
                                    <Button
                                        mt={'15px'}
                 type={"button"}
                 fontSize={"16px"}
                                        colorScheme={'standard'}
                                        onClick={takePhoto}
                >
                    Take photo
                                    </Button>
                                    {
                                        images?.length > 0 ?
                                        <Button
                                        mt={'15px'}
                 type={"button"}
                 fontSize={"16px"}
                                        colorScheme={'success'}
                                                onClick={() => {
                                                    openImages(images)
                                                    setImages([]);
                                                    closeCamera();
                                        }}
                >
                    Open
                                            </Button>
                                            :
                                            <></>
                                      }
            </Center>
                                </Box>
           </Center>
            </ModalBody>
            <Box>
                        <Center textAlign={'center'} columnGap={'5px'} flexWrap={'wrap'}>
                        {
                            images?.map((img:any) => {
                                return (
                                    <Image objectFit={'contain'} rounded={'md'} w={'200px'} h={'200px'} src={img}/>
                                )
                            })
              }
                       </Center>
            </Box>
          </ModalContent>
        </Modal>
      </>
    )
}
  
export default CameraModal;
import axiosInstance from "../util/axios";

export async function save(data:any) {
   return await axiosInstance.post("/services/", data);
}

export async function edit(id:any,data:any) {
    return await axiosInstance.put(`/services/${id}`,data)
}

export async function deleteById(id:any) {
    return await axiosInstance.delete(`/services/${id}`);
}

export async function findAll() {
    return await axiosInstance.get("/services/");
}

export async function askService(data:any) {
    return await axiosInstance.post("/services/ask", data);
}
 
export async function findAllRequests(limit:number,page:number,status:string) {
    return await axiosInstance.get(`/services/requests/${limit}/${page}/${status}`)
}

export async function setServiceRequestStatus(id:any,data:any) {
    return await axiosInstance.put(`/services/status/${id}`,data);
}
import { useEffect, useState } from "react";
import { CiCircleQuestion, CiSearch } from "react-icons/ci";
import {
  Flex,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  useToast,
  Spinner,
  Box,
  Center,
  Heading,
    Button,
    FormControl,
    FormLabel,
    Select,
    Textarea,
    Table,
  Tbody,
  Td,
  Th,
  Thead,
    Tr,
    Tabs, TabList, TabPanels, Tab, TabPanel
} from "@chakra-ui/react";
import SelectComponent from 'react-select';
import { deleteById, edit, findAll, save } from "../../apis/serviceApis";
import { BsTrash } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import WarningModal from "../../components/WarningModal";
import EditServiceModal from "../../components/EditServiceModal";

const Services = () => {
    const toast = useToast();
    const [loading3, setLoading3] = useState<Boolean>(true);

    const [services, setServices] = useState<any>(
        [
        ]
    )

    const getServices = async () => {
        setLoading3(true);
        const res: any = await findAll();
        setLoading3(false);

        if (res?.data?.length > 0) {
            setServices(res.data.map((service: any) => {
                return { value: service?._id, label: service?.serviceName,description: service?.description }
            }))
        }
    }

    const [formData2, setFormData2] = useState<any>(
        {
            serviceName: undefined,
            description: undefined
        }
    );

    const [loading2, setLoading2] = useState<Boolean>(false);
    const [currentService, setCurrentService] = useState<any>({});
    
    const saveService= async(e:React.FormEvent) => {
        e.preventDefault();
        setLoading2(true);
        try {
        const res: any = await save(formData2);
        if (res?.data?.status == true) {
            toast({
                title: "Success",
                description: res?.data?.message,
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        getServices();
        }
        setLoading2(false);
        }
        catch (error: any) {
        setLoading2(false);
            toast({
                title: "Failed",
                description: error?.response?.data?.message || error?.message,
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        }
    }

    useEffect(() => {
        getServices();
    }, [])
    
  const [updating, setUpdating] = useState<Boolean>(false);
    const [isOpen, setIsOpen] = useState<Boolean>(false);
    const [currentId, setCurrentId] = useState<any>(undefined);

    const [updating2, setUpdating2] = useState<Boolean>(false);
    const [isOpen2, setIsOpen2] = useState<Boolean>(false);
    
    const deleteService = async (id:any) => {
        try{
            setUpdating(true);
            const res: any = await deleteById(id);
            setUpdating(false);
            
            if (res?.data?.status == true) {
              setIsOpen(false);
              toast({
                  title: "Success",
                  description: res?.data?.message,
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                  position: "top-right",
              });
            }
            getServices();
          }
          catch (error: any) {
            setUpdating(false);
            toast({
              title: "Failed",
              description: error?.response?.data?.message || error?.message,
              status: "error",
              duration: 2000,
              isClosable: true,
              position: "top-right",
          });
          }
    }

    const updateService= async(newData:any) => {
        setUpdating2(true);
        try {
            const res: any = await edit(currentService?.value, newData);
            if (res?.data?.status == true) {
                setCurrentService({});
                setIsOpen2(false);
        getServices();
            toast({
                title: "Success",
                description: res?.data?.message,
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        }
        setUpdating2(false);
        }
        catch (error: any) {
            setUpdating2(false);
            toast({
                title: "Failed",
                description: error?.response?.data?.message || error?.message,
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        }
    }

    return (
        <Box pt={"0px"}>
            
<Box mb={'20px'}>
          <Box className="tb-title">
            <Text>Manage services</Text>
          </Box>
            </Box>
            
            <Box boxShadow={"0 2px 14px #c6c6c680"} mb={'40px'} bg={'white'} p={'50px'} rounded={'xl'} w={'full'}>
                <Box mb={'30px'} onSubmit={saveService} as={'form'}>
                <Box mb={'20px'} className="tb-title">
            <Text>Register service</Text>
          </Box>
                <Flex mb={"10px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            Service name
              </FormLabel>
              <Input
                onChange={(e: any) => {
                    setFormData2({...formData2, serviceName: e.target.value })
                }}
                py={'20px'}
                type={"text"}
                placeholder="Service name"
              />
                </FormControl>
                    </Flex>

                    <FormControl isRequired mb={"20px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                 Additional description
                </FormLabel>
                <Flex>
                        <Textarea onChange={(e: any) => {
                             setFormData2({...formData2, description: e.target.value })
                  }} placeholder="Describe the service here" ></Textarea>
                </Flex>
              </FormControl>
                    
                    <Box>
                <Button
                 type={"submit"}
                 fontSize={"16px"}
                    colorScheme={'standard'}
                    isLoading={loading2==true ? true : false}
                    loadingText={"Submitting..."}
                >
                    Save
                </Button>
                </Box>
                </Box>

                <Box mb={'20px'} className="tb-title">
            <Text>All services</Text>
                </Box>
                
                {
                    loading3 ?
                    <Center columnGap={'10px'} py={'20px'}>
                    <Spinner color='standard.700' /><Text>Loading...</Text>
                                      </Center>
                        :
                        <>
                            {
                                services?.length > 0 ?
                                <Table>
                                <Tr>
                                    <Th>Service name</Th>
                                    <Th>Action</Th>
                                </Tr>
                                {
                                    services?.map((service: any) => {
                                        return (
                                            <Tr bg={currentId!=undefined && currentId?.toString()==service?.value?.toString()? '#fbdada': "white"}>
                                    <Td>{service?.label}</Td>
                                    <Td>
                                        <Flex columnGap={'10px'}>
                                                        <Button onClick={() => {
                                                            setIsOpen2(true);
                                                            setCurrentService(service)
                                            }} colorScheme={'standard'}><BiEdit size={20} /></Button>
                                                        <Button onClick={() => {
                                                            setIsOpen(true);
                                                            setCurrentId(service?.value)
                                        }} colorScheme={'danger'}><BsTrash size={20} /></Button>
                                        </Flex>
                                    </Td>
                                </Tr>
                                        )
                                    })
                                }
                            </Table>
                                    :
                                    <Center py={'10px'}>
                                        <Text>No services found</Text>
                                    </Center>
                            }
                        </>
                }
            </Box>

            <WarningModal
          title={'Delete service'}
          isOpen={isOpen}
          onConfirm={() => {
              deleteService(currentId);
          }}
          updating={updating}
          btnTitle={'Delete service'}
          btnColor={"danger"}
          loadingText={"Deleting..."}
          desc={`Are you sure you want to delete this service?`}
                onClose={() => {
                    setCurrentId(undefined)
            setIsOpen(false);
          }}
            />
            
            <EditServiceModal
                isOpen={isOpen2}
                onConfirm={(newData:any) => {
                    updateService(newData);
                }}
                currentData={currentService}
                updating={updating2}
          loadingText={"Saving changes..."}
            onClose={() => {
                setCurrentService({})
            setIsOpen2(false);
          }}
            />

        </Box>
    )
}

export default Services;
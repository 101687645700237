import { useEffect, useState } from "react";
import { CiCircleQuestion, CiSearch } from "react-icons/ci";
import {
  Flex,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  useToast,
  Spinner,
  Box,
  Center,
  Heading,
    Button,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    VStack
} from "@chakra-ui/react";
import "../../dashboard.css";
import { useLocation, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import LandLoard from "./LandLoard";
import Tenant from "./Tenant";
import LandLordRequests from "./LandLordRequests";
import TenantRequests from "./TenantRequests";

const Renting = () => {

    const navigate = useNavigate();
    const [settingTabIndex,setTabIndex] = useState<number>(0);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
  const TAB_TITLES = ['Landlord Requests',"Tenant Requests", 'Landlord','Tenant'];
      
      useEffect(() => {
        const tab: any = searchParams.get("tab");
        if ((parseInt(tab) >= 0) == false) {
          return navigate("/renting?tab=0")
        }
        setTabIndex(parseInt(tab));
      }, [searchParams]);
    
    const onChangeTab = (index: number) => {
      navigate(`/renting?tab=${index}`);
    }


    return(
        <>
        <VStack px="30px" pb="10px" pt={'35px'} >
      <Tabs variant="soft-rounded" w="100%" index={settingTabIndex} onChange={onChangeTab}>
        <TabList justifyContent={'center'}>
          {TAB_TITLES.map((title, idx) => (
            <Tab key={`tab-${idx}`}>{title}</Tab>
          ))}
        </TabList>
                    <TabPanels>
        <TabPanel>
                        <LandLordRequests></LandLordRequests>
                        </TabPanel>
              <TabPanel>
              <TenantRequests></TenantRequests>
          </TabPanel>
                        <TabPanel>
                        <Box boxShadow={"0 2px 14px #c6c6c680"} mb={'40px'} bg={'white'} p={'50px'} rounded={'xl'} w={'full'}>
                                <LandLoard />
                                </Box>
                          </TabPanel>
                        <TabPanel>
                            <Box boxShadow={"0 2px 14px #c6c6c680"} mb={'40px'} bg={'white'} p={'50px'} rounded={'xl'} w={'full'}>
                                <Tenant/>
                                </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
      </VStack>
    </>
    )
}

export default Renting;
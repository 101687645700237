import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
  Img,
  Center,
  useBreakpointValue, 
  Button
} from "@chakra-ui/react";
import DEFAULT_AVATAR from "../assets/images/user.png";
import logo from "../assets/images/logo-blue.png";
import { FiChevronDown } from 'react-icons/fi';
import useAuth from "../apis/useAuth";
import { useEffect, useState } from "react";
import { AiFillDatabase, AiOutlineDatabase, AiOutlineShoppingCart } from "react-icons/ai";
import { MdOutlineMiscellaneousServices, MdSell } from "react-icons/md";
import LoginModal from "./LoginModal";
import { BsFillHouseAddFill } from "react-icons/bs";

const Header = ({ isMobile, isSmMobile, isMdDesktop }: { isMobile: boolean | undefined, isSmMobile: boolean | undefined, isMdDesktop: boolean | undefined }) => {
  const { logout, userInfo, getLoggedInUser }: any = useAuth();
  const { pathname } = useLocation();
  useEffect(() => {
    getLoggedInUser();
  }, []);
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  
  return (
        <>
      <Box
     w={`100%`}
     h="70px"
     bg="bg.blue"
     py="0px"
     position="fixed"
     top="0px"
        zIndex="999"
        boxShadow={'md'}
      >
        <Button id={"toggleUserInfo"} style={{display: "none"}} onClick={() => {
    getLoggedInUser();
        }}></Button>
        <Center h={'100%'}>
          <Box h={'100%'} w={"80%"} display={"flex"} justifyContent={"space-between"}>

            <Center>
              <Link to={'/'}><Img
                h={'35px'}
                cursor={'pointer'}
                src={logo}
                ml={'-15px'}
              ></Img></Link>
            </Center>
            
            <Center h={'100%'} justifyContent={'center'} gridGap={'20px'}>
              <Link to={"/services"} ><Center rounded={'sm'} py={'7px'} px={'14px'} bg={pathname == "/services" ? "#C6FFE9" : ""} fontSize={'15px'} color={pathname == "/services" ? "#08509F" : "text.white"} _hover={{ bg: "#C6FFE9", color: "#08509F" }} columnGap={'3px'}><MdOutlineMiscellaneousServices size={20} /> <Text>Services</Text></Center></Link>
            <Link to={"/renting"} ><Center rounded={'sm'} py={'7px'} px={'14px'} bg={pathname == "/renting" ? "#C6FFE9" : ""} fontSize={'15px'} color={pathname == "/renting" ? "#08509F" : "text.white"} _hover={{ bg: "#C6FFE9",color: "#08509F" }} columnGap={'3px'}><BsFillHouseAddFill size={20} /> <Text>Renting</Text></Center></Link>
              <Link to={"/sell-property"} ><Center rounded={'sm'} py={'7px'} px={'14px'} bg={pathname == "/sell-property" ? "#C6FFE9" : ""} fontSize={'15px'} color={pathname == "/sell-property" ? "#08509F" : "text.white"} _hover={{ bg: "#C6FFE9",color: "#08509F" }} columnGap={'3px'}><MdSell size={20} /> <Text>Sell</Text></Center></Link>
              <Link to={"/buy-property"} ><Center rounded={'sm'} py={'7px'} px={'14px'} bg={pathname == "/buy-property" ? "#C6FFE9" : ""} fontSize={'15px'} color={pathname == "/buy-property" ? "#08509F" : "text.white"} _hover={{ bg: "#C6FFE9", color: "#08509F" }} columnGap={'3px'}><AiOutlineShoppingCart size={20} /> <Text>Buy</Text></Center></Link>
              <Link to={"/buy-property-requests"} ><Center rounded={'sm'} py={'7px'} px={'14px'} bg={pathname == "/buy-property-requests" ? "#C6FFE9" : ""} fontSize={'15px'} color={pathname == "/buy-property-requests" ? "#08509F" : "text.white"} _hover={{ bg: "#C6FFE9",color: "#08509F" }} columnGap={'3px'}><AiFillDatabase size={20} /> <Text>Buy Requests</Text></Center></Link>
              <Link to={"/sell-property-requests"} ><Center rounded={'sm'} py={'7px'} px={'14px'} bg={pathname == "/sell-property-requests" ? "#C6FFE9" : ""} fontSize={'15px'} color={pathname == "/sell-property-requests" ? "#08509F" : "text.white"} _hover={{ bg: "#C6FFE9",color: "#08509F" }} columnGap={'3px'}><AiOutlineDatabase size={20} /> <Text>Sell Requests</Text></Center></Link>
</Center>

            {
              localStorage.getItem("access_token") && userInfo?
<Menu >
  <MenuButton
    as={IconButton}
    aria-label="Options"
    h={"100% !important"}
    paddingBottom={"0px"}
    backgroundColor={"transparent !important"}
    border={"none !important"}
                variant="outline"
                position={'relative'}
                left={'15px'}
  >
    <Flex
      fontSize={"14px"}
      fontWeight={"500"}
      color={"#fff"}
      position={"relative"}
    >
      <Center
        h={"100% !important"}
        px={"20px"}
                      >
                          {!isMobile &&(
                              <Text isTruncated maxW={'120px'}> {userInfo?.username}
                                  &nbsp;&nbsp;</Text>)
                          }
        <Box h={"50px"} width={"50px"}>
          <Img
            h={"100%"}
            w={"100%"}
            borderRadius={"50%"}
            src={userInfo?.photo}
            alt=""
          />
        </Box>
        &nbsp;&nbsp;
        <Text>
          <FiChevronDown size={22}></FiChevronDown>
        </Text>
      </Center>
    </Flex>
  </MenuButton>

              <MenuList boxShadow="xl" rounded="md">
                <Box px={'10px'} py={'5px'}>
                  <Text fontSize={'15px'} color={'text.light'} fontWeight={'semibold'}>{userInfo?.username}</Text>
                </Box>
                  <Link to={'/settings'}><MenuItem  _hover={{color: "standard.700"}} style={{ fontSize: 15 }}>Settings</MenuItem></Link>
                <MenuItem
                  _hover={{color: "text.error"}}
      onClick={() => {
        logout();
      }}
      style={{ fontSize: 15 }}
    >
     Sign out
    </MenuItem>
  </MenuList>
</Menu>
                :
                <Center h={'100%'}>
                  <Button id={'loginBtn'} onClick={() => {
                    setIsOpen(true); 
}} bg="bg.green" color={'#08509F'}  _hover={{ bg: "#98f4d1",color: "#08509F" }}>Login/Signup</Button>
                  </Center>
            }
</Box>
          </Center>
      </Box>

      <LoginModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </>
    )
}

export default Header;
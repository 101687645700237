import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Center,
    Button,
    FormControl,
    FormLabel,
    Input,
    Text,
    Image,
    Flex,
    Alert,
    AlertTitle,
    AlertIcon,
    AlertDescription,
    CloseButton
  } from "@chakra-ui/react";
  import "../pages/Authentication/auth.css";
  import { Link, useNavigate } from "react-router-dom";
  import logo from "../../assets/images/logo.png";
  import { FiLogIn } from "react-icons/fi";
  import { useState } from "react";
  import { FiChevronDown } from 'react-icons/fi';
  import { MdEmail, MdOutlineEmail, MdPassword } from "react-icons/md";
  import { RiLockPasswordFill } from "react-icons/ri";
import useAuth from "../apis/useAuth";
import { AiOutlineClose } from 'react-icons/ai'
import SignupModal from "./SignupModal";

function LoginModal({ isOpen, onClose }: any) {
    
    const { loginAdmin, loading } = useAuth();
    const [closed, setClosed] = useState<Boolean>(true);
    const [opened, setOpened] = useState<Boolean>(false);

    const [error, setError] = useState<string>('');

    const [data, setData] = useState<any>({
      email: undefined,
      password: undefined,
    });
  
    const login = async(e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setClosed(true);
        const res: any = await loginAdmin(data);
        if (res.status == false) {
            setError(res.message)
        setClosed(false);
        }
    };

    return (
      <>
            <Modal size={'lg'} isCentered isOpen={isOpen} onClose={() => {
                     setError('');
                setClosed(true);
                onClose();
            }}>
            <ModalOverlay
    >
          <ModalContent>
                        <Flex justifyContent={'space-between'} px={"43px"} pt={'35px'}><Text fontSize={'25px'} mb={'15px'} color="standard.700" fontWeight={'bold'}>Lariqueza</Text>
                            <Button onClick={onClose} bg={'white'} className="custom-btn"><AiOutlineClose size={24}></AiOutlineClose></Button>
                            </Flex>
            <ModalBody>
            <Box w={"100%"}>
      <Center pb={"20px"} w={"100%"}>
        <Center
          // boxShadow="base"
          bg={"white"}
          className="auth-forms"
          w={"100%"}
                                        px={"20px"}
                                        pt={'5px'}
          pb={"30px"}
          rounded={"md"}
        >
          <Box w={"100%"} onSubmit={login} as={"form"}>
            <Box w={"100%"} mb={"20px"}>
              <Text fontSize={'25px'} color={'text.light'} fontWeight={'bold'}>Sign In</Text>
                                            </Box>
                                            
                                            {
                                                !closed ?
                                                <Alert position={'relative'} mb={'10px'} rounded={'md'} status='error'>
                                                <AlertIcon />
                                                        <AlertDescription>{ error}</AlertDescription>
                                                                                              <Box right={'10px'} position={'absolute'}>
                                                                                              <CloseButton
                                                      alignSelf='flex-end'
                                                      position='relative'
                                                      right={-1}
                                                      top={-0.5}
                                                                onClick={() => {
                                                          setClosed(true)
                                                      }}
                                                    />
                                              </Box>
                                              </Alert>
                                                    :
                                                    <></>
                                            }


                                            <FormControl isRequired mb={"15px"}>
                                            <FormLabel fontWeight="medium" fontSize="14px" mb={"4px"}>
                Email address
              </FormLabel>
                <Flex position={'relative'}>
              <Text zIndex={'999999'} pl={'17px'} color={'text.light'} top={'14px'} left={'0px'} position={'absolute'}><MdEmail size={22} /></Text>
              <Input
                onChange={(e: any) => {
                  setData({ ...data, email: e.target.value });
                }}
                py={'25px'}
                type={"email"}
                //   rounded={"full"}
                pl={'47px'}
                placeholder="Email address"
              />
              </Flex>
            </FormControl>

            <FormControl isRequired mb={"10px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"4px"}>
                Password
              </FormLabel>
              <Flex position={'relative'}>
              <Text zIndex={'999999'} pl={'17px'} color={'text.light'} top={'12px'} left={'0px'} position={'absolute'}><RiLockPasswordFill size={22} /></Text>
              <Input
                onChange={(e: any) => {
                  setData({ ...data, password: e.target.value });
                }}
                py={'25px'}
                  type={"password"}
                pl={'47px'}
                placeholder="Password"
                // rounded={"full"}
              />
              </Flex>
            </FormControl>

            <Box mb={'30px'} w={'100%'} >
                                                <Text
                                                    as={'span'}
                color={"standard.300"}
                _hover={{ color: "standard.700",textDecoration: "underline" }}
              >
                <Link to={"#"}>Forgot Password?</Link>
              </Text>
            </Box>

            <Box>
              <Button
                type={"submit"}
                fontWeight={"bold"}
                fontSize={"16px"}
                py={'25px'}
                colorScheme="standard"
                                                    // rounded={"full"}
                                                    isLoading={loading == true ? true : false}
                                                    loadingText={'Signing In...'}
                w={"100%"}
                mb={"20px"}
                display={"flex"}
                columnGap={"5px"}
              >
                <FiLogIn size={20}></FiLogIn>
                Sign In
              </Button>
            </Box>

            <Flex columnGap={"5px"}>
              <Text>Don't have an account yet?</Text>{" "}
              <Text
                                                    color={"standard.300"}
                                                    as={'span'}
                                                    onClick={() => {
                                                        onClose();
                                                        setError('');
                                                        setClosed(true);
                                                        setOpened(true);
                                                    }}
                                                    cursor={'pointer'}
                _hover={{ color: "standard.500",textDecoration: "underline" }}
              >
                Sign up
              </Text>
            </Flex>
          </Box>
        </Center>
      </Center>
    </Box>
            </ModalBody>
          </ModalContent>
                </ModalOverlay>
            </Modal>
            
            <SignupModal
                isOpened={opened}
                onClosing={() => {
                    setOpened(false);
                }}
            ></SignupModal>
      </>
    )
}
  
export default LoginModal;
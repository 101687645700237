import { useState ,useEffect} from "react";
import { CiCircleQuestion, CiSearch } from "react-icons/ci";
import {
    Flex,
    Text,
    Input,
    Menu,
    MenuButton,
    MenuList,
    IconButton,
    useToast,
    Spinner,
    Box,
    Center,
    Heading,
    Button,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Textarea,
} from "@chakra-ui/react";
import "../../dashboard.css";
import { AiFillCalendar, AiFillPlusCircle } from "react-icons/ai";
import { setRentRequestStatus } from "../../apis/rentingApis";
import { useLocation, useNavigate ,useParams } from "react-router-dom";
import { BiArrowBack, BiFilterAlt } from "react-icons/bi";
import WarningModal from "../../components/WarningModal";
import jwt_decode from "jwt-decode";
import { getRentRequestById } from "../../apis/rentingApis";

const RentingRequestDetails = () => {
    const [loadingRequest, setLoadingRequest] = useState<Boolean>(true);
  const [request, setRequest] = useState<any>({});
  const location = useLocation();
  const toast = useToast();
  const searchParams = new URLSearchParams(location.search)
  const navigate = useNavigate();
  const params = useParams();

  const [decodedHeader,setDecodedHeader] = useState<any>({});

  useEffect(() => {
    const token:any = window.localStorage.getItem("access_token");
      setDecodedHeader(jwt_decode(token))
    },[])

    const getRequestById = async (id:any) => {
        setLoadingRequest(true);
        const res: any = await getRentRequestById(id);
        setLoadingRequest(false);
      setRequest(res.data)
  }
  
  useEffect(() => {
    const par: any = document.getElementById("description");
    if (par && request?.description) {
      par.innerHTML = request?.description;
    }
  },[request])

  useEffect(() => {
      getRequestById(params.id);
  }, [params]);

  const [updating, setUpdating] = useState<Boolean>(false);

  const updateStatus = async (id: any, status: any) => {
    try{
      setUpdating(true);
    const res:any = await setRentRequestStatus(id, { status })
      setUpdating(false);
      setRequest({...request, status: status})
      
      if (res?.data?.status == true) {
        setIsOpen(false);
        toast({
            title: "Success",
            description: res?.data?.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
        });
    }
    }
    catch (error: any) {
      setUpdating(false);
      toast({
        title: "Failed",
        description: error?.response?.data?.message || error?.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
    });
    }
  }
  
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const [title, setTittle] = useState<string>('');
  const [action, setAction] = useState<string>('');
    
    return(
        <>
         <Box pt={'30px'}>
    
                <Box className="table-nav">
          <Box className="tb-title">
          <Flex columnGap={'30px'}>
                <Text>Rent request as {request?.userType == 1 ? "Landlord" : "Tenant"}</Text>
                <Text color={'text.light'}>{ new Date(request?.createdAt)?.toLocaleDateString() + " "+ new Date(request?.createdAt)?.toLocaleTimeString()}</Text>
              </Flex>
          </Box>

          <Box className="flex">
            <Button
              colorScheme="primary"
              fontSize={"15px"}
              fontWeight={400}
              borderRadius={"10px"}
                onClick={() => {
                navigate(-1)
              }}
            >
              <BiArrowBack size={18} color={"white"}></BiArrowBack>
              &nbsp;Back
            </Button>
          </Box>
        </Box>
           
        <Box boxShadow={"0 2px 14px #c6c6c680"} mb={'40px'} bg={'white'} p={'50px'} rounded={'xl'} w={'full'}>
            {
              loadingRequest ?
                <Center columnGap={'10px'} py={'20px'}>
<Spinner color='standard.700' /><Text>Loading...</Text>
                  </Center>
                :
                <Box>
                  {
                    request?.description ?
                    <Box pb={`${request?.userType == 1 ? "20px" : "0px"}`}>
                    <Textarea readOnly resize={'none'} outline={'none'} border={'none'} id={'description'} />
                      </Box>
                      :
                      <></>
                  }
                                {
                                    request?.userType == 1 ?
                                    <Flex mb={'30px'} textAlign={'center'} rowGap={'8px'} columnGap={'8px'} flexWrap={'wrap'}>
                                    {
                                        request?.images?.map((img:any) => {
                                            return (
                                                <Image cursor={'pointer'} objectFit={'cover'} rounded={'md'} w={'250px'} h={'250px'} src={img?.url}/>
                                            )
                                        })
                          }
                                        </Flex>
                                        :
                                        <></>
 }

                  <Table>
                    <Tbody>
                      <Tr>
                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Names : </Text>
                    <Text color={'text.light'}>{ request?.user?.username}</Text>
                    </Flex>
                        </Td>
                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Telephone : </Text>
                    <Text color={'text.light'}>{ request?.phone}</Text>
                    </Flex>
                        </Td>
                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Email : </Text>
                    <Text color={'text.light'}>{ request?.email}</Text>
                    </Flex>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>District : </Text>
                    <Text color={'text.light'}>{ request?.district}</Text>
                    </Flex>
                        </Td>
                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Sector : </Text>
                    <Text color={'text.light'}>{ request?.sector}</Text>
                    </Flex>
                        </Td>
                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Cell : </Text>
                    <Text color={'text.light'}>{ request?.cell}</Text>
                    </Flex>
                        </Td>
                      </Tr>

                      <Tr>
                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Village : </Text>
                    <Text color={'text.light'}>{ request?.village}</Text>
                    </Flex>
                                            </Td>
                                            {
                                                request?.userType == 1 ?
                                                    <>
                                                    <Td>
                                                        <Flex columnGap={'3px'}>
                                                            <Text color={'text.information'} fontWeight={'semibold'}>Negotiable : </Text>
                                                            <Text color={'text.light'}>{request?.negotiable}</Text>
                                                        </Flex>
                                                        </Td>
                                                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Amount : </Text>
                    <Text color={'text.light'}>{ request?.amount? request?.amount?.toLocaleString() + " RWF" : "N/A"}</Text>
                    </Flex>
                                                        </Td>
                                                        </>
                                                    :
                                                    <>
                                                    <Td>
                                                        <Flex columnGap={'3px'}>
                                                            <Text color={'text.information'} fontWeight={'semibold'}>Minimum amount: </Text>
                                                            <Text color={'text.light'}>{  request?.minimumAmount? request?.minimumAmount?.toLocaleString() + " RWF" : "N/A"}</Text>
                                                        </Flex>
                                                        </Td>
                                                        
                                                        <Td>
                                                        <Flex columnGap={'3px'}>
                                                            <Text color={'text.information'} fontWeight={'semibold'}>Maximum amount: </Text>
                                                            <Text color={'text.light'}>{  request?.maximumAmount? request?.maximumAmount?.toLocaleString() + " RWF" : "N/A"}</Text>
                                                        </Flex>
                                                    </Td>

                                                        </>
                                                    }
                      </Tr>

                                        <Tr>
                                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Property purpose : </Text>
                    <Text color={'text.light'}>{request?.type==1? "Commercial" : "Residential"}</Text>
                    </Flex>
                        </Td>
                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Type : </Text>
                    <Text color={'text.light'}>{ request?.subType}</Text>
                    </Flex>
                                            </Td>
                                            {
                                                request?.userType == 1 ?
                                                    <Td>
                                                        <Flex columnGap={'3px'}>
                                                            <Text color={'text.information'} fontWeight={'semibold'}>Number of bedrooms : </Text>
                                                            <Text color={'text.light'}>{request?.numberOfBedRooms}</Text>
                                                        </Flex>
                                                    </Td>
                                                    :   
                                               <></>     
                                            }
                      </Tr>

                      <Tr>
                      {
                        request?.userType == 1 ?
                            <>
                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Number of bathrooms? : </Text>
                    <Text color={'text.light'}>{ request?.numberOfBathRooms}</Text>
                    </Flex>
                        </Td>
                        <Td>
                        <Flex columnGap={'3px'}>
                    <Text color={'text.information'} fontWeight={'semibold'}>Number of toilets : </Text>
                    <Text color={'text.light'}>{request?.numberOfToilets}</Text>
                    </Flex>
                        </Td>
                            </>
                          :
                          <></>
                        }
                      </Tr>
                    </Tbody>
                  </Table>

                  {
                  (decodedHeader?.role == "ADMIN" || decodedHeader?.role == "SUPER_ADMIN") &&  request?.status == "PENDING" ?
                    <Flex py={'20px'} columnGap={'15px'} justifyContent={'flex-end'}>
                        <Button
                          onClick={() => {
                            setIsOpen(true);
                            setTittle(`Reject ${request?.userType == 1 ? "Landlord" : "Tenant"} Rent Request`);
                            setAction("reject")
                      }}
                          colorScheme="danger">Reject</Button>
                        <Button onClick={() => {
                          setIsOpen(true);
                          setTittle(`Approve ${request?.userType == 1 ? "Landlord" : "Tenant"} Rent Request`);
                          setAction("approve")
                    }} colorScheme="success">Approve</Button>
                  </Flex>
                      :
                      <></>
                  }

                  {
                    request?.user?._id == decodedHeader?._id && request?.status == "PENDING" ?
                    <Flex py={'20px'} columnGap={'15px'} justifyContent={'flex-end'}>
                    {/* <Button
                      onClick={() => {
                        setIsOpen(true);
                        setTittle(`Delete ${request?.userType == 1 ? "Landlord" : "Tenant"} Rent Request`);
                        setAction("delete")
                  }}
                      colorScheme="danger">Delete request</Button> */}
              </Flex>
                      :
                      <></>
                  }
                  </Box>
          }
        </Box>
               
        </Box>
        
        <WarningModal
          title={title}
          isOpen={isOpen}
          onConfirm={() => {
            if (action != "delete") {
              updateStatus(params.id,action=="reject"? "REJECTED" : "APPROVED");
            }
          }}
          updating={updating}
          btnTitle={action == "reject" ? "Reject" : action == "delete" ? "Delete request" : "Approve"}
          btnColor={action == "reject" || action=="delete" ? "danger" : "success"}
          loadingText={action == "reject" ? "Rejecting..." : action == "delete" ? "Deleting..." : "Approving..."}
          desc={`Are you sure you want to ${action} this request?`}
          onClose={() => {
            setIsOpen(false);
          }}
        />
        </>
    )
}

export default RentingRequestDetails; 
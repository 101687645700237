
import axiosInstance from "../util/axios";

export async function addAnAdmin(data: any) {
    return await axiosInstance.post("/users/addAdmin", data);
}

export async function changeUserPassword(data: any) {
    return await axiosInstance.put("/users/changePassword", data);
}

export async function changeProfile(data: any) {
    return await axiosInstance.put("/users/", data);
}

export async function getAdmins() {
    return await axiosInstance.get("/users/admins/all/fetch/info");
}
import LandLordRequestsTable from "./LandLordRequestsTable";
import { useState ,useEffect} from "react";
import { CiCircleQuestion, CiSearch } from "react-icons/ci";
import {
    Flex,
    Text,
    Input,
    Menu,
    MenuButton,
    MenuList,
    IconButton,
    useToast,
    Spinner,
    Box,
    Center,
    Heading,
    Button,
    MenuItem,
} from "@chakra-ui/react";
import "../../dashboard.css";
import { AiFillCalendar, AiFillPlusCircle } from "react-icons/ai";
import { getBuyPropertyRequests, getSellPropertyRequests, getUserSellPropertyRequests } from "../../apis/propertyApis";
import { useLocation, useNavigate } from "react-router-dom";
import { BiFilterAlt } from "react-icons/bi";
import jwt_decode from "jwt-decode";
import { getRentPropertyRequests, getUserRentPropertyRequests } from "../../apis/rentingApis";

const LandLordRequests = () => {
    const [loadingRequests, setLoadingRequests] = useState<Boolean>(true);
  const [requests, setRequests] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [pageNum, setPageNum] = useState<number>(0);
  const navigate = useNavigate();
  const [status, setStatus] = useState<string>("PENDING");

    const getRequests = async (page:number,status:string) => {
      setLoadingRequests(true);
      const token:any = window.localStorage.getItem("access_token");
      const decodedHeader: any = jwt_decode(token);
      let res: any;
      if(decodedHeader?.role == "USER")
      {
        res = await getUserRentPropertyRequests(10, page, status,1);
      }        
      if (decodedHeader?.role == "ADMIN"  || decodedHeader?.role == "SUPER_ADMIN") {
        res = await getRentPropertyRequests(10, page,status,1);
      }
        setLoadingRequests(false);
      setRequests(res?.data?.data)
      setTotal(res?.data?.totalElements)
    }
  
  useEffect(() => {
      const page: any = searchParams.get("page");
      const tab: any = searchParams.get("tab");

        if ((parseInt(tab) > 0) == true) {
            return;
      }
      
    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    return navigate("/renting?page=1&tab=0");
  }, [searchParams]);

  useEffect(() => {
    if (pageNum > 0 && status) {
      getRequests(pageNum,status);
    }
  }, [pageNum,status]);

  const headers: any = [
        {
          name: "Username",
      },
      {
        name: "District",
      },
    {
      name: "Amount",
    },
    {
      name: "Contact phone",
      },
      {
        name: "Type",
      },
      {
        name: "Date",
        },
      {
        name: "Action",
      }
      ];
    
    return(
        <>
                <Box pt={'30px'}>
    
                <Box className="table-nav">
          <Box className="tb-title">
            <Text>{ status?.toLocaleUpperCase()} Landlord requests</Text>
          </Box>

          <Box className="flex">
            <Center position={'relative'} left={'30px'} className="flex">
              <Input
                bg="#ffff"
                borderColor="#ffff"
                placeholder="Search..."
                colorScheme="primary"
                type={"text"}
                boxShadow="xs"
                className="search-field"
              />
              <Text className={"search-icon"}>
                <CiSearch size={20} />
              </Text>
              </Center>
              
              <Menu>
                              <MenuButton
                                as={Button}
                                aria-label="Options"
                                className={"custom_dropdown_button"}
                  variant="outline"
                >
                              <Button
              colorScheme="primary"
                    fontSize={"15px"}
                    position={'relative'} left={'15px'}
              fontWeight={400}
              borderRadius={"10px"}
            >
              <BiFilterAlt size={18} color={"white"}></BiFilterAlt>
              &nbsp;Filter
            </Button>
                  </MenuButton>
                              <MenuList
                                boxShadow="xl"
                                rounded="md"
                                className="menuListContainer menuListContainer_nopad"
                              >
                  <MenuItem onClick={() => {
                    setStatus("PENDING")
                    navigate("/renting?page=1&tab=0");
                                }} className="menu-list menu-list-edit">
                                  {" "}
                                  {/* <Box mr={'2px'} className="dropdown-icon">
                                    <MdEdit size={18}></MdEdit>
                                    {"   "}
                                  </Box> */}
                                Pending requests
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                    setStatus("APPROVED")
                      navigate("/renting?page=1&tab=0");
                                  }}
                    className="menu-list menu-list-edit">
                                  {" "}
                                  {/* <Box mr={'2px'} className="dropdown-icon">
                                    <MdEdit size={18}></MdEdit>
                                    {"   "}
                                  </Box> */}
                                Approved requests
                  </MenuItem>

                  <MenuItem
                       onClick={() => {
                        setStatus("REJECTED")
                          navigate("/renting?page=1&tab=0");
                                      }}
                    className="menu-list menu-list-edit">
                                  {" "}
                                  {/* <Box mr={'2px'} className="dropdown-icon">
                                    <MdEdit size={18}></MdEdit>
                                    {"   "}
                                  </Box> */}
                                Rejected requests
                  </MenuItem>
                  
                </MenuList>
                </Menu>
          </Box>
        </Box>
                
                <Box
        className="customers-table-container w-full"
        marginBottom={"40px"}
      >
            <LandLordRequestsTable
                headers={headers}
                data={requests}
                loading={loadingRequests}
                totalNum={total}
              pageNum={pageNum}
              itemsPerPage={10}
                searching={false}
                />
                </Box>
            </Box>
        </>
    )
}

export default LandLordRequests; 
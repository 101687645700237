import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
    FormControl,
    FormLabel,
    Select,
    Textarea,
    Flex,
    Input
  } from '@chakra-ui/react'
import React,{useState,useEffect} from 'react'

function EditServiceModal({ isOpen, onClose, updating, onConfirm, loadingText, currentData }: any) {
    
    const [formData, setFormData] = useState<any>(
        {
            serviceName: undefined,
            description: undefined
        }
    );

    useEffect(() => {
        setFormData({
            serviceName: currentData?.label,
            description: currentData?.description
        })
    },[currentData])

    return (
      <>
            <Modal size={'lg'} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay
    >
                    <ModalContent onSubmit={(e: React.FormEvent) => {
                        e.preventDefault();
                        onConfirm(formData)
          }} as={'form'}>
                        <ModalHeader>Edit service</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Flex mb={"10px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            Service name
              </FormLabel>
              <Input
                onChange={(e: any) => {
                    setFormData({...formData, serviceName: e.target.value?.trim() })
                }}
                                        py={'20px'}
                                        defaultValue={currentData?.label}
                type={"text"}
                placeholder="Service name"
              />
                </FormControl>
                    </Flex>

                    <FormControl isRequired mb={"20px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Service description
                </FormLabel>
                <Flex>
                                    <Textarea
                                        defaultValue={currentData?.description}
                                        onChange={(e: any) => {
                             setFormData({...formData, description: e.target.value?.trim() })
                  }} placeholder="Describe the service here" ></Textarea>
                </Flex>
              </FormControl>
            </ModalBody>
            <ModalFooter>
               <Button onClick={onClose}>Close</Button>
                            <Button isDisabled={( (formData?.serviceName?.trim()?.length>0 && formData?.description?.trim()?.length>0) && ((formData?.serviceName?.trim()!=currentData?.label?.trim()) || (formData?.description?.trim() != currentData?.description?.trim())))? false : true } type={'submit'} ml={'10px'} loadingText={loadingText} isLoading={updating ? true : false} colorScheme={'standard'}
                            >Save changes</Button>
            </ModalFooter>
          </ModalContent>
                </ModalOverlay>
                </Modal>
      </>
    )
}
  
export default EditServiceModal;
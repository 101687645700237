import { useEffect, useState } from "react";
import { CiCircleQuestion, CiSearch } from "react-icons/ci";
import {
  Flex,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  useToast,
  Spinner,
  Box,
  Center,
  Heading,
  Button,
} from "@chakra-ui/react";
import "../../dashboard.css";
import { AiFillCalendar, AiFillPlusCircle } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import useCarOwners from "../../apis/useCarOwners";
import BuyPropertyForm from "./BuyPropertyForm";
import { buyProperty } from "../../apis/propertyApis";

const BuyProperty = () => {
  const toast = useToast();
  const [loading, setLoading] = useState<Boolean>(false);

  const sendRequest = async(data:any) => {
    try {
      setLoading(true);
      const res: any = await buyProperty(data);
      setLoading(false);

      if (res?.data?.status == true) {
        toast({
            title: "Success",
            description: res?.data?.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
        });
    }
    }
    catch (error: any) {
      setLoading(false);
        console.log(error)
            toast({
                title: "Failed",
                description: error?.response?.data?.message || error?.message,
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
    }
  }

  return (
    <>
      <Box pt={"30px"}>
        <Box mb={'20px'} className="table-nav">
          <Box className="tb-title">
            <Text>Buy property</Text>
          </Box>
        </Box>

        <Box boxShadow={"0 2px 14px #c6c6c680"} mb={'40px'} bg={'white'} p={'50px'} rounded={'xl'} w={'full'}>
          <BuyPropertyForm loading={loading} onSubmit={(formData: any) => {
            sendRequest(formData);
        }} />
        </Box>
      </Box>
    </>
  );
};

export default BuyProperty;

import { Box, Center, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
import { ReactNode, useEffect, useState, useMemo } from "react";
import Header from "../components/Header";

interface UnAuthLayoutProps {
    children?: ReactNode;
}

const HomeLayout = ({ children }: UnAuthLayoutProps) => {

  const [isMdDesktop, setIsMdDesktop] = useState<any>(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isSmMobile = useBreakpointValue({ base: true, sm: false });
  const isDesktop = useBreakpointValue({ base: true, lg: false })

  if (localStorage.getItem("access_token")) {
    // return <Navigate to="/buy-property-requests" />;
  }

    return (
      <Flex width={"100%"} position={"relative"}>
      <Box
        w={`100%`}
        float={"right"}
        position={"absolute"}
        right={"0px"}
        h={"100vh"}
        overflowY={"auto"}
        backgroundColor={"#ffff"}
        pt={"0px"}
        pl={"0px"}
        transitionDuration={"0.3s"}
      >
        <Header isMobile={isMobile} isSmMobile={isSmMobile} isMdDesktop={isMdDesktop} />
        <Center>
            <Box w={'100%'}>
            {children}
          </Box>
        </Center>
      </Box>
    </Flex>
    );
}

export default HomeLayout;
import axiosInstance from "../util/axios";

export async function buyProperty(data:any) {
   return await axiosInstance.post("/property/buy", data);
}

export async function getBuyPropertyRequests(limit:number,page:number,status:string) {
    return await axiosInstance.get(`/property/buy/${limit}/${page}/${status}`)
}

export async function getUserBuyPropertyRequests(limit:number,page:number,status:string) {
    return await axiosInstance.get(`/property/buy/user/${limit}/${page}/${status}`);
}

export async function sellProperty(data:any) {
    return await axiosInstance.post("/property/sell", data);
}

export async function getSellPropertyRequests(limit:number,page:number,status:string) {
    return await axiosInstance.get(`/property/sell/${limit}/${page}/${status}`);
}

export async function getUserSellPropertyRequests(limit:number,page:number,status:string) {
    return await axiosInstance.get(`/property/sell/user/${limit}/${page}/${status}`);
}

export async function getSellRequestById(id:any) {
    return await axiosInstance.get(`/property/sell/${id}`);
}

export async function getBuyRequestById(id:any) {
    return await axiosInstance.get(`/property/buy/${id}`);
}

export async function setBuyRequestStatus(id:any,data:any) {
    return await axiosInstance.put(`/property/buy/${id}`,data);
}

export async function setSellRequestStatus(id:any,data:any) {
    return await axiosInstance.put(`/property/sell/${id}`,data);
}
import {
    Flex,
    Text,
    Input,
    Menu,
    MenuButton,
    MenuList,
    IconButton,
    useToast,
    Spinner,
    Box,
    Center,
    Heading,
    Button,
    FormControl,
    FormLabel,
    Select,
    Textarea,
    Radio,
    RadioGroup,
    Stack,
    Image
} from "@chakra-ui/react";
import { useState,useEffect } from "react";
import { Provinces, Districts, Sectors, Cells, Villages } from 'rwanda';
import SelectComponent from 'react-select';
import CameraModal from "../SellProperty/CameraModal";
import { BsCamera } from "react-icons/bs";
import { MdAddPhotoAlternate } from "react-icons/md";
import React from "react";
import { rent } from "../../apis/rentingApis";
import { useNavigate } from "react-router-dom";

const LandLoard = () => {
    const toast = useToast();
    const [allDistricts, setAllDistricts] = useState([])
    const [allSectors, setAllSectors] = useState([])
    const [allCells, setAllCells] = useState([])
    const [allVillages, setAllVillages] = useState([])
    const [negotiable, setNegotiable] = useState<string>('');
    const [dist, SetDist] = useState(null)
    const [cell, SetCell] = useState(null)
    const [sector, SetSector] = useState(null)
    const [village, SetVillage] = useState(null)
    const navigate = useNavigate();
    const [loading, setLoading] = useState<Boolean>(false);

    useEffect(()=>{
    let arr:any = [];
    Districts()?.map((d:any)=>{
     arr.push({value: d, label: d})
    })
    setAllDistricts(arr)
},[])

const getSectors =(dist:any)=>{
    SetDist(dist)
    Provinces().map((p:any)=>{
        Districts(p)?.map((d:any)=>{
            if(d == dist){
                let arr:any = []
                 Sectors(p,dist)?.map((s:any)=>{
                 arr.push({value: s, label: s})
                });
                setAllSectors(arr);
                return 0;
            }
        })
    })
}

const getCells =(sector:any)=>{
    SetSector(sector)
    Provinces().map((p:any)=>{
        Districts(p)?.map((d:any)=>{
            Sectors(p,d)?.map((s:any)=>{
                if(s == sector){
                        let arr:any = []
                 Cells(p,d,sector)?.map((c:any)=>{
                 arr.push({value: c, label: c})
                });
                setAllCells(arr);
                    return 0;
                }
            })
        })
    })
}

const getVillage =(cell:any)=>{
    SetCell(cell)
    Provinces().map((p:any)=>{
        Districts(p)?.map((d:any)=>{
            Sectors(p,d)?.map((s:any)=>{
                if(s == sector){
                Cells(p,d,s)?.map((c:any)=>{
                    if(c == cell){
                        console.log(p,d,s,c)

                let arr:any = []
             Villages(p,d,s,cell)?.map((v:any)=>{
                 arr.push({value: v, label: v})
                });
                setAllVillages(arr);

                        return 0;
                    }
                })
            }
            })
            
        })
    })
    }
    
    const landOptions = [
        {value: 'Residential', label: 'Residential'},
        {value: 'Commercial', label: 'Commercial'},
        {value: 'Agricultural', label: 'Agricultural'},
         {value: 'Industrial', label: 'Industrial'} 
    ];

    const buildingOptions = [
        {value: 'Residential', label: 'Residential'},
        {value: 'Commercial', label: 'Commercial'},
         {value: 'Other', label: 'Other'}
    ];

    const [type, setType] = useState<number>(1);

    const [formData, setFormData] = useState<any>(
        {
            type: 1,
            district: undefined,
            sector: undefined,
            cell: undefined,
            village: undefined,
            subType: undefined,
            amount: undefined,
            description: undefined,
            email: undefined,
            phone: undefined,
            negotiable: undefined,
            numberOfBathRooms: undefined,
            numberOfBedRooms: undefined,
            numberOfToilets: undefined,
            images: [],
            userType: 1
        }
    );

    const handleSubmit = async(e:React.FormEvent) => {
        e.preventDefault();
        const data: any = formData;
        try {
            setLoading(true);
            const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/duldhdjsj/image/upload';
            const imagesInfo = [];

            if (images.length == 0) {
                toast({
                    title: "Failed",
                    description: "Please upload images of the property",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                    position: "top-right",
                });
            setLoading(false);
                return;
            }
            
              for (let i = 0; i < images.length; i++) {
                  let data = {
                      "file": images[i],
                      "upload_preset": "s1ef0r8m",
                  }
      
                  const r = await fetch(CLOUDINARY_URL, {
                      body: JSON.stringify(data),
                      headers: {
                          'content-type': 'application/json'
                      },
                      method: 'POST',
                  });
      
                  let imageInfo = await r.json()
                  console.log(imageInfo)
                  imagesInfo.push({ url: imageInfo?.url, publicId: imageInfo?.public_id })
            }
            
            const res: any = await rent({...data, images: imagesInfo,negotiable:negotiable});
            setLoading(false);
      
            if (res?.data?.status == true) {
              toast({
                  title: "Success",
                  description: res?.data?.message,
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                  position: "top-right",
              });
                return navigate("/renting?page=1&tab=0")
          }
          }
          catch (error: any) {
            setLoading(false);
              console.log(error)
                  toast({
                      title: "Failed",
                      description: error?.response?.data?.message || error?.message,
                      status: "error",
                      duration: 2000,
                      isClosable: true,
                      position: "top-right",
                  });
          }
    }

    const [isOpen, setIsOpen] = useState<Boolean>(false);

    const toggleCamera = async () => {
        setIsOpen(true);
    }

    const [images,setImages] = React.useState<any>([])

    const loadFile = function(event:any) {
        if (images?.length < 7) {
            const reader = new FileReader();
            let file =event.target.files[0]
            reader.addEventListener("load", () => {
              setImages([reader.result,...images]);
            }, false);
          
            if (file) {
              reader.readAsDataURL(file);
            }
        }
      }

    return (
        <Box onSubmit={handleSubmit} as={'form'}>
    <Box mb={'20px'}  fontWeight={'semibold'} fontSize={'20px'}>
            <Text>Landlord</Text>
          </Box>
<Flex mb={"15px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            Select property type
                    </FormLabel>
                    <Select onChange={(e: any) => {
                        setType(e.target.value)
                        setFormData({...formData, type: e.target.value,subType: undefined })
                    }}>
                        <option value={''} disabled={true}>Select property type</option>
                        <option value={1} selected={true}> Commercial</option>
                        <option value={2}>Residential</option>
                    </Select>
                </FormControl>

                <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            Select type
                    </FormLabel>
                    <Select
                        onChange={(e: any) => {
                            setFormData({...formData, subType: e.target.value })
                        }}
                    >
                        {
                            formData?.type == 1 ?
                                <>
                                    <option selected disabled value="">Select type</option>
                                    <option value="Show rooms">Show rooms</option>
                                    <option value="Retail">Retail</option>
                                    <option value="Office">Office</option>
                                    <option value="Warehouse">Warehouse</option>
                                    <option value="Land and plots">Land and plots</option>
                                    <option value="Factories">Factories</option>
                                </>
                                :
                                <>
                                    <option disabled value="">Select type</option>
                                    <option value="Apartment">Apartment</option>
                                    <option value="Duplexes">Duplexes</option>
                                    <option value="Villas">Villas</option>
                                    <option value="Penthouses">Penthouses</option>
                                    <option value="Townhouses">Townhouses</option>
                                </>
                        }
                            </Select>
                </FormControl>
            </Flex>

<Flex mb={"15px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            District
                    </FormLabel>
                    <SelectComponent
                        required={true}
             options={allDistricts} onChange={(e:any)=>{
                 getSectors(e.value)
                 setFormData({...formData, district: e.value })
                }}
                placeholder="Select District"
            />
                </FormControl>

                <FormControl >
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            Sector
                    </FormLabel>
                    <SelectComponent
                        options={allSectors} onChange={(e: any) => {
                            getCells(e.value)
                            setFormData({...formData, sector: e.value })
            }} placeholder={'Select Sector'}  />
                </FormControl>
            </Flex>
            
            <Flex mb={"15px"} columnGap={'25px'}>
               <FormControl>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            Cell
                    </FormLabel>
                    <SelectComponent
             options={allCells} onChange={(e:any)=>{
                            getVillage(e.value)
                            setFormData({...formData, cell: e.value })
                            }}
                placeholder="Select Cell"
            />
                </FormControl>

                <FormControl >
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
              Village
                    </FormLabel>
                    <SelectComponent
                        options={allVillages} onChange={(e:any)=>{
                            SetVillage(e.value)
                            setFormData({...formData, village: e.value })
                                    }}
                        placeholder={'Select Village'} />
                </FormControl>
                </Flex>
            
                <Flex mb={"15px"} columnGap={'25px'}>
            <FormControl isRequired >
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Is the price negotiable? 
                </FormLabel>
                    <RadioGroup onChange={(val:any) => {
                        setNegotiable(val)
                        setFormData({...formData, amount: undefined })
                    }
                    } value={negotiable}>
      <Stack direction='row'>
        <Radio value='yes'>Yes</Radio>
        <Radio value='no'>No</Radio>
      </Stack>
                    </RadioGroup>
                    </FormControl>

                {
                    negotiable == 'no' ?
                    <FormControl isRequired >
                    <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                    Renting amount
                    </FormLabel>
                    <Flex>
                      <Input
                                name="number"
                                onChange={(e: any) => {
                                    setFormData({...formData, amount: e.target.value })
                                    }}
                        type={"number"}
                        placeholder="Amount (RWF)"
                        maxLength={10}
                      />
                    </Flex>
                        </FormControl>
                        :
                        <></>
           }
                </Flex>

                <Flex mb={"15px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
               Number of bedrooms
              </FormLabel>
              <Input
                onChange={(e: any) => {
                    setFormData({...formData, numberOfBedRooms: e.target.value })
                }}
                py={'20px'}
                type={"number"}
                placeholder="How many bedrooms?"
              />
                </FormControl>

                <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
               Number of bathrooms
                </FormLabel>
                <Flex>
                  <Input
                            name="bathrooms"
                            onChange={(e: any) => {
                                setFormData({...formData, numberOfBathRooms: e.target.value })
                                }}
                    type={"number"}
                    placeholder="How many bathrooms ?"
                    maxLength={9}
                  />
                </Flex>
              </FormControl>
                
            </Flex>

            <Flex mb={"15px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
               Number of toilets
              </FormLabel>
              <Input
                onChange={(e: any) => {
                    setFormData({...formData, numberOfToilets: e.target.value })
                }}
                py={'20px'}
                type={"number"}
                placeholder="How many toilets?"
              />
                </FormControl>

                <FormControl>

              </FormControl>
                
            </Flex>

            <Flex mb={"15px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Contact email
              </FormLabel>
              <Input
                onChange={(e: any) => {
                    setFormData({...formData, email: e.target.value })
                }}
                py={'20px'}
                type={"email"}
                placeholder="Email address"
              />
                </FormControl>

                <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Contact phone number
                </FormLabel>
                <Flex>
                  <Input
                            name="phoneNumber"
                            onChange={(e: any) => {
                                setFormData({...formData, phone: e.target.value })
                                }}
                    type={"number"}
                    placeholder="+250"
                    maxLength={9}
                  />
                </Flex>
              </FormControl>
                
            </Flex>

            <Box mb={'20px'}>
            <FormControl mb={"10px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                 Additional description
                </FormLabel>
                <Flex>
                        <Textarea onChange={(e: any) => {
                             setFormData({...formData, description: e.target.value })
                  }} placeholder="Describe the property here" ></Textarea>
                </Flex>
              </FormControl>
            </Box>

            <Box mb={'20px'}>
                <FormControl>
                    <Flex mb={'10px'} columnGap={'30px'}>
                        <Text>Upload photos (7 MAX)</Text>
                    </Flex>

                    <Flex columnGap={'25px'}>
                        <Box>
                            <FormLabel>
                            <Center columnGap={'3px'}>
                                    <Button onClick={() => {
                                        const file: any = document.getElementById("fileInput");
                                        if (file) {
                                            file.click();
                                        }
                        }} colorScheme={'standard'} rounded={'full'}>
                        <MdAddPhotoAlternate size={24}></MdAddPhotoAlternate>
                            </Button>
                            <Text onClick={() => {
                                        const file: any = document.getElementById("fileInput");
                                        if (file) {
                                            file.click();
                                        }
                        }} as={'span'} cursor={'pointer'}>From device</Text>
                        </Center> 
                            </FormLabel>
                            <Input accept="image/*" onChange={loadFile} id={'fileInput'} style={{display: "none"}} type={'file'} />
                </Box>
                    <Center columnGap={'3px'}>
                        <Button onClick={toggleCamera} colorScheme={'standard'} rounded={'full'}>
                        <BsCamera size={24}></BsCamera>
                            </Button>
                            <Text as={'span'} cursor={'pointer'} onClick={toggleCamera}>Use camera</Text>
                        </Center>
                    </Flex>
              </FormControl>
            </Box>

            <Flex mb={'30px'} textAlign={'center'} columnGap={'5px'} flexWrap={'wrap'}>
                        {
                            images?.map((img:any) => {
                                return (
                                    <Image objectFit={'contain'} rounded={'md'} w={'200px'} h={'200px'} src={img}/>
                                )
                            })
              }
                       </Flex>

            <Box>
                <CameraModal
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    openImages={(imgs: any) => {
                        if ([...images, ...imgs]?.length <= 7) {
                            setImages([...imgs,...images])
                            setIsOpen(false);
                        }
                    }}
                    isOpen={isOpen}
                />
            </Box>
            
            <Box>
                <Button
                 type={"submit"}
                 fontSize={"16px"}
                    colorScheme={'standard'}
                    isLoading={loading==true ? true : false}
                    loadingText={"Submitting..."}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    )
}

export default LandLoard;
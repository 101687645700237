import { useEffect, useState } from "react";
import { CiCircleQuestion, CiSearch } from "react-icons/ci";
import {
  Flex,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  useToast,
  Spinner,
  Box,
  Center,
  Heading,
    Button,
    FormControl,
    FormLabel,
    Select,
    Textarea,
    Table,
  Tbody,
  Td,
  Th,
  Thead,
    Tr,
    Tabs, TabList, TabPanels, Tab, TabPanel, Alert, AlertDescription, AlertTitle, AlertIcon
} from "@chakra-ui/react";
import SelectComponent from 'react-select';
import { askService, findAll, save } from "../../apis/serviceApis";
import { BsTrash } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { useParams } from "react-router-dom";

const AskService = () => {
    const toast = useToast();
    const [loading3, setLoading3] = useState<Boolean>(true);
  const [loading, setLoading] = useState<Boolean>(false);
  const searchParams: any = useParams();

    const [formData, setFormData] = useState<any>(
        {
            serviceId: undefined,
            name: undefined,
            email: undefined,
            phone: undefined
        }
    );

    const [services, setServices] = useState<any>(
        [
        ]
    )

    const getServices = async () => {
        setLoading3(true);
        const res: any = await findAll();
        setLoading3(false);

        if (res?.data?.length > 0) {
            setServices(res.data.map((service: any) => {
                return { value: service?._id, label: service?.serviceName }
            }))
        }
    }

    useEffect(() => {
        getServices();
    }, [])
  
  const [submitted, setSubmitted] = useState<Boolean>(false);

  useEffect(() => {
    setSubmitted(false)
  },[searchParams])
    
    const askServices = async (e:React.FormEvent) => {
        try {
            e.preventDefault();
            setLoading(true);
            const res: any = await askService(formData);
            setLoading(false);
          if (res?.data?.status == true) {
            setSubmitted(true);
                // toast({
                //     title: "Success",
                //     description: res?.data?.message,
                //     status: "success",
                //     duration: 2000,
                //     isClosable: true,
                //     position: "top-right",
                // });
            }
        }
        catch (error: any) {
            setLoading(false);
            toast({
                title: "Failed",
                description: error?.response?.data?.message || error?.message,
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            }); 
        }
    }
    
    return (
        <Box pt={"0px"}>
        <Box mb={'20px'}>
        <Box className="tb-title">
          <Text>Ask for other services</Text>
        </Box>
      </Box>

        <Box boxShadow={"0 2px 14px #c6c6c680"} mb={'40px'} bg={'white'} p={'50px'} rounded={'xl'} w={'full'}>
          {
            submitted ?
              <>
            <Alert
            status='success'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='200px'
          >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              Request submitted!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              Thanks for submitting your service request. Our team will get back to you soon.
            </AlertDescription>
          </Alert>
                <Center py={'20px'}>
                  <Button onClick={() => {
                    setSubmitted(false);
                  }} colorScheme="success">Ok</Button>
          </Center>
                </>
              :
              <>
              <Box onSubmit={askServices} as={'form'}>
                  <Flex mb={"15px"} columnGap={'25px'}>
              <FormControl isRequired >
            <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
          Choose service
                  </FormLabel>
                          <SelectComponent
                              required
                                options={services} onChange={(e: any) => {
                        setFormData({...formData, serviceId: e?.value})
          }} placeholder={'Choose service'}  />
                  </FormControl> 
                  
                  <FormControl isRequired >
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
              Your name
              </FormLabel>
              <Flex>
                <Input
                          onChange={(e: any) => {
                              setFormData({...formData, name: e.target.value })
                              }}
                  type={"text"}
                  placeholder="Your name"
                />
              </Flex>
                  </FormControl>
                  </Flex>

                  <Flex mb={"20px"} columnGap={'25px'}>
             <FormControl isRequired>
            <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
              Contact email
            </FormLabel>
            <Input
              onChange={(e: any) => {
                  setFormData({...formData, email: e.target.value })
              }}
              py={'20px'}
              type={"email"}
              placeholder="Email address"
            />
              </FormControl>

              <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Contact phone number
              </FormLabel>
              <Flex>
                <Input
                          name="phoneNumber"
                          onChange={(e: any) => {
                              setFormData({...formData, phone: e.target.value })
                              }}
                  type={"number"}
                  placeholder="+250"
                  maxLength={9}
                />
              </Flex>
            </FormControl>
              
                  </Flex>
                  
                  <Box>
              <Button
               type={"submit"}
               fontSize={"16px"}
                  colorScheme={'standard'}
                  isLoading={loading==true ? true : false}
                  loadingText={"Submitting..."}
              >
                  Submit
              </Button>
          </Box>

                  
        </Box>
              
              </>
          }

         
      </Box>
    </Box>
    )
}

export default AskService;
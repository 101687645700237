import {
    Flex,
    Text,
    Input,
    Menu,
    MenuButton,
    MenuList,
    IconButton,
    useToast,
    Spinner,
    Box,
    Center,
    Heading,
    Button,
    FormControl,
    FormLabel,
    Select,
    Textarea
} from "@chakra-ui/react";
import { useState,useEffect } from "react";
import { Provinces, Districts, Sectors, Cells, Villages } from 'rwanda';
import SelectComponent from 'react-select';

const BuyPropertyForm = ({onSubmit,loading}:any) => {

    const [allDistricts, setAllDistricts] = useState([])
    const [allSectors, setAllSectors] = useState([])
    const [allCells, setAllCells] = useState([])
    const [allVillages, setAllVillages] = useState([])

    const [dist, SetDist] = useState(null)
    const [cell, SetCell] = useState(null)
    const [sector, SetSector] = useState(null)
    const [village, SetVillage] = useState(null)

    useEffect(()=>{
    let arr:any = [];
    Districts()?.map((d:any)=>{
     arr.push({value: d, label: d})
    })
    setAllDistricts(arr)
},[])

const getSectors =(dist:any)=>{
    SetDist(dist)
    Provinces().map((p:any)=>{
        Districts(p)?.map((d:any)=>{
            if(d == dist){
                let arr:any = []
                 Sectors(p,dist)?.map((s:any)=>{
                 arr.push({value: s, label: s})
                });
                setAllSectors(arr);
                return 0;
            }
        })
    })
}

const getCells =(sector:any)=>{
    SetSector(sector)
    Provinces().map((p:any)=>{
        Districts(p)?.map((d:any)=>{
            Sectors(p,d)?.map((s:any)=>{
                if(s == sector){
                        let arr:any = []
                 Cells(p,d,sector)?.map((c:any)=>{
                 arr.push({value: c, label: c})
                });
                setAllCells(arr);
                    return 0;
                }
            })
        })
    })
}

const getVillage =(cell:any)=>{
    SetCell(cell)
    Provinces().map((p:any)=>{
        Districts(p)?.map((d:any)=>{
            Sectors(p,d)?.map((s:any)=>{
                if(s == sector){
                Cells(p,d,s)?.map((c:any)=>{
                    if(c == cell){
                        console.log(p,d,s,c)

                let arr:any = []
             Villages(p,d,s,cell)?.map((v:any)=>{
                 arr.push({value: v, label: v})
                });
                setAllVillages(arr);

                        return 0;
                    }
                })
            }
            })
            
        })
    })
    }
    
    const landOptions = [
        {value: 'Residential', label: 'Residential'},
        {value: 'Commercial', label: 'Commercial'},
        {value: 'Agricultural', label: 'Agricultural'},
         {value: 'Industrial', label: 'Industrial'} 
    ];

    const buildingOptions = [
        {value: 'Residential', label: 'Residential'},
        {value: 'Commercial', label: 'Commercial'},
         {value: 'Other', label: 'Other'}
    ];

    const [type, setType] = useState<number>(1);

    const [formData, setFormData] = useState<any>(
        {
            type: 1,
            district: undefined,
            sector: undefined,
            cell: undefined,
            village: undefined,
            purpose: undefined,
            maximumAmount: undefined,
            description: undefined,
            email: undefined,
            phone: undefined
        }
    );

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault();
        onSubmit(formData);
    }

    return (
        <Box onSubmit={handleSubmit} as={'form'}>

<Flex mb={"15px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            Select property type
                    </FormLabel>
                    <Select onChange={(e: any) => {
                        setType(e.target.value)
                        setFormData({...formData, type: e.target.value })
                    }}>
                        <option value={''} disabled={true}>Select property type</option>
                        <option value={1} selected={true}>Land</option>
                        <option value={2}>Building</option>
                    </Select>
                </FormControl>

                <FormControl isRequired>
                </FormControl>
            </Flex>

<Flex mb={"15px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            District
                    </FormLabel>
                    <SelectComponent
                        required={true}
             options={allDistricts} onChange={(e:any)=>{
                 getSectors(e.value)
                 setFormData({...formData, district: e.value })
                }}
                placeholder="Select District"
            />
                </FormControl>

                <FormControl >
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            Sector
                    </FormLabel>
                    <SelectComponent
                        options={allSectors} onChange={(e: any) => {
                            getCells(e.value)
                            setFormData({...formData, sector: e.value })
            }} placeholder={'Select Sector'}  />
                </FormControl>
            </Flex>
            
            <Flex mb={"15px"} columnGap={'25px'}>
               <FormControl>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            Cell
                    </FormLabel>
                    <SelectComponent
             options={allCells} onChange={(e:any)=>{
                            getVillage(e.value)
                            setFormData({...formData, cell: e.value })
                            }}
                placeholder="Select Cell"
            />
                </FormControl>

                <FormControl >
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
              Village
                    </FormLabel>
                    <SelectComponent
                        options={allVillages} onChange={(e:any)=>{
                            SetVillage(e.value)
                            setFormData({...formData, village: e.value })
                                    }}
                        placeholder={'Select Village'} />
                </FormControl>
                </Flex>
            
<Flex mb={"15px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
            Purpose
              </FormLabel>
              <Select
                onChange={(e: any) => {
                            setFormData({...formData, purpose: e.target.value })
                }}
                    >
                        <option value={''} selected={true} disabled={true}>Select purpose</option>
                        {
                            type == 1 ?
                                <>
                                    {
                                        landOptions?.map((item: any) => {
                                            return (
                                                <option value={item?.value}>{item?.label}</option>
                                            )
                                        })
                                    }
                                </>
                                :
                                <>
                                {
                                    buildingOptions?.map((item: any) => {
                                        return (
                                            <option value={item?.value}>{item?.label}</option>
                                        )
                                    })
                                }
                            </>
                        }
                        </Select>
                </FormControl>

                <FormControl isRequired >
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Maximum amount
                </FormLabel>
                <Flex>
                  <Input
                            name="number"
                            onChange={(e: any) => {
                                setFormData({...formData, maximumAmount: e.target.value })
                                }}
                    type={"number"}
                    placeholder="Maxium amount (RWF)"
                    maxLength={10}
                  />
                </Flex>
              </FormControl>
                
                </Flex>


            <Flex mb={"15px"} columnGap={'25px'}>
               <FormControl isRequired>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Contact email
              </FormLabel>
              <Input
                onChange={(e: any) => {
                    setFormData({...formData, email: e.target.value })
                }}
                py={'20px'}
                type={"email"}
                placeholder="Email address"
              />
                </FormControl>

                <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                  Contact phone number
                </FormLabel>
                <Flex>
                  <Input
                            name="phoneNumber"
                            onChange={(e: any) => {
                                setFormData({...formData, phone: e.target.value })
                                }}
                    type={"number"}
                    placeholder="+250"
                    maxLength={9}
                  />
                </Flex>
              </FormControl>
                
            </Flex>

            <Box mb={'20px'}>
            <FormControl mb={"10px"}>
                <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                 Additional description
                </FormLabel>
                <Flex>
                        <Textarea onChange={(e: any) => {
                             setFormData({...formData, description: e.target.value })
                  }} placeholder="Describe the property here" ></Textarea>
                </Flex>
              </FormControl>
            </Box>
            
            <Box>
                <Button
                 type={"submit"}
                 fontSize={"16px"}
                    colorScheme={'standard'}
                    isLoading={loading==true ? true : false}
                    loadingText={"Submitting..."}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    )
}

export default BuyPropertyForm;
import { useState } from "react";
import axiosInstance from "../util/axios";
import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

const useAuth = () => {

    const toast = useToast();
    const [loading, setLoading] = useState<Boolean>(false);
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState<any>({});
    const location = useLocation();
  const searchParams = new URLSearchParams(location.search); 

    const logout = () => {
        window.localStorage.removeItem("access_token");
        navigate("/app");
    }

    const registerAdmin = async (data:any) =>{
        try {
            setLoading(true);
            const res: any = await axiosInstance.post("/users/", data);
            setLoading(false);

            if (res?.data?.status == true) {
                toast({
                    title: "Success",
                    description: res?.data?.message,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                    position: "top-right",
                });
                const service: any = searchParams.get("service");
                let path = "/buy-property-requests";

                if (service) {
                    switch (service) {
                        case "sell": path = "/sell-property"
                            break;
                        case "buy": path = "/buy-property"
                            break;
                        case "rent": path = "/renting?tab=2"
                            break;
                        default: path = "/buy-property-requests"
                            break;
                    }
                }

                window.localStorage.setItem("access_token", res?.data?.access_token);
                navigate(path)
                return {status: true,message: res?.data?.message}
            }
        }
        catch (error: any) {
            setLoading(false);
            return { message: error?.response?.data?.message || error?.message, status: false };
        }
    }

    const loginAdmin = async (data:any) =>{
        try {
            setLoading(true);
            const res: any = await axiosInstance.post("/users/login", data);
            setLoading(false);

            if (res?.data?.status == true) {
                toast({
                    title: "Success",
                    description: res?.data?.message,
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                    position: "top-right",
                });
                const service: any = searchParams.get("service");
                let path = "/buy-property-requests";

                if (service) {
                    switch (service) {
                        case "sell": path = "/sell-property"
                            break;
                        case "buy": path = "/buy-property"
                            break;
                        case "rent": path = "/renting?tab=2"
                            break;
                        default: path = "/buy-property-requests"
                            break;
                    }
                }

                window.localStorage.setItem("access_token", res?.data?.access_token);
                navigate(path)
                return {status: true,message: res?.data?.message}
            }
        }
        catch (error: any) {
            setLoading(false);
            console.log(error)
            return { message: error?.response?.data?.message || error?.message, status: false };
        }
    }

    const getLoggedInUser = async () => {
      try {
          const res:any = await axiosInstance.get(
              `/users/signedIn/user/info`
        );
            
        setUserInfo(res?.data);
            
        }
      catch (error: any) {
          console.log(error)
          if (error?.response?.status == 401) {
            //   logout();
            // toast({
            //     title: "Failed",
            //     description: "Please login first",
            //     status: "error",
            //     duration: 2000,
            //     isClosable: true,
            //     position: "top-right",
            // });
              return;
          }
          toast({
            title: "Failed",
            description: error?.response?.data?.message || error?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top-right",
        });
        }
  };

    return {
        registerAdmin,
        loading,
        logout,
        loginAdmin,
        getLoggedInUser,
        userInfo
    }
} 

export default useAuth;
import {
  Box,
  Image,
  Center,
  Text,
  Heading,
  Flex,
  Card, CardHeader, CardBody, CardFooter,Button,SimpleGrid
} from "@chakra-ui/react";
import { useEffect,useState } from "react";
import OtherServices from "../OtherServices";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../../assets/images/slide1.jpg"
import slide2 from "../../assets/images/slide2.jpg"
import slide3 from "../../assets/images/slide4.avif"
import slide4 from "../../assets/images/slide4.jpg"
import { deleteById, findAll, save } from "../../apis/serviceApis";
import { useLocation, useNavigate } from "react-router-dom";

const Home = () => {

  const settings:any = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search); 

  const navigate = useNavigate();

  const [height, setHeight] = useState<any>(0);
  const [currentService, setCurrentService] = useState<any>({});

  const slides = [
    {
      img: slide1,
      tittle: "",
      desc: ""
    },
    {
      img: slide2,
      tittle: "",
      desc: ""
    },
    {
      img: slide3,
      tittle: "",
      desc: ""
    },
    {
      img: slide4,
      tittle: "",
      desc: ""
}
  ]

  const [services, setServices] = useState<any>([{
    serviceName: "Selling property",
    description: "Connecting sellers of properties with interested buyers. Your trusted partner for selling your property",
    _id: "sell",
    auth: true,
    path: "/sell-property"
  },
  {
    serviceName: "Buying property",
    description: "Connecting buyers of properties with interested sellers. Your trusted partner for buying your property",
    _id: "buy",
    auth: true,
    path: "/buy-property"
    },
    {
      serviceName: "Renting house",
      description: "Discover the perfect rental properties. Your next home is just a click away. Explore a wide range of rental options today.",
      _id: "rent",
      auth: true,
    path: "/renting"
    },
  ])

  const getServices = async () => {
    const res: any = await findAll();
    if (res?.data?.length > 0) {
        setServices([...services,...res.data.map((service: any) => {
            return service
        })])
    }
  }

  const toggleLogin = () => {
    const toggleLogin: any = document.getElementById("loginBtn");
    if (toggleLogin) {
      toggleLogin.click();
    }
  }

  useEffect(() => {
    getServices();
    toggleLogin();
    setHeight(window.innerHeight);
  }, []);


  const visitService = (service:any) => {
    let path = "/buy-property-requests";

    if (service) {
        switch (service) {
            case "sell": path = "/sell-property"
                break;
            case "buy": path = "/buy-property"
                break;
            case "rent": path = "/renting?tab=2"
                break;
            default: path = "/services?tab=2"
                break;
      }
      navigate(path)
      
    }
  }

  return (
    <Box pos={'relative'} w={"100%"}>
      <Center p={'80px'} pt={'110px'} pb={'70px'} bg={'bg.blue'} w={'100%'} >
        <Box textAlign={'center'}>
          <Text textAlign={'center'} color={'white'} fontWeight={'semibold'} fontSize={'35px'}>Welcome to Lariqueza</Text>
</Box>
      </Center>

      <Center py={'30px'} w={'100%'}>
      <Box w={'80%'} pt={'30px'} >
        <Text mb={'35px'} color={'standard.700'} fontSize={'25px'}>Our Services</Text>
          <Center>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="30px">
          {
            services?.map((service: any) => {
              return (
                <Card onClick={() => {
                  setCurrentService(service);

                  if (localStorage.getItem("access_token")) {
                    return visitService(service?._id);
}

                  if (service?.auth == true) {
                    toggleLogin();
                    return navigate(`/?service=${service?._id}`)
                  }
                  return navigate("/services?tab=0");
                }} boxShadow={"0 2px 14px #c6c6c680"} className="card_service" align='center'>
                  <CardHeader pb={'0px'}>
                    <Heading color={'standard.700'} textAlign={'center'} size='md'>{ service?.serviceName}</Heading>
                  </CardHeader>
                  <CardBody pb={'0px'}>
                    <Text textAlign={'center'}>{ service?.description}</Text>
                  </CardBody>
                  <CardFooter>
                    {/* <Button colorScheme='standard'>Request</Button> */}
                  </CardFooter>
                </Card>
              )
            })
              }
              </SimpleGrid>
        </Center>
      </Box>
      </Center>
      
      <Center w={'100%'}>
      <Box w={'80%'} pt={'30px'} py={'20px'}>
        <Text mb={'35px'} color={'standard.700'} fontSize={'25px'}>Contact us</Text>
          <Center>

          </Center>
          </Box>
          </Center>

      {/* <Box h={height/1.5} top={'70px'} pos={'fixed'} w={'100%'}>
       <Slider {...settings}>
        {
          slides?.map((slide: any) => {
            return (
              <Box h={height/1.5} w={'100%'}>
<Image h={'100%'} w={'100%'} objectFit={'cover'} objectPosition={'center'} src={slide?.img} />
              </Box>
            )
          })
      }
    </Slider> */}
      </Box>
  );
};

export default Home;

import axiosInstance from "../util/axios";

export async function rent(data:any) {
   return await axiosInstance.post("/renting/", data);
}

export async function getRentPropertyRequests(limit:number,page:number,status:string,userType: number) {
    return await axiosInstance.get(`/renting/${limit}/${page}/${status}/${userType}`);
}

export async function getUserRentPropertyRequests(limit:number,page:number,status:string,userType:number) {
    return await axiosInstance.get(`/renting/user/${limit}/${page}/${status}/${userType}`);
}

export async function getRentRequestById(id:any) {
    return await axiosInstance.get(`/renting/${id}`);
}

export async function setRentRequestStatus(id:any,data:any) {
    return await axiosInstance.put(`/renting/${id}`,data);
}
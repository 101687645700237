import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import AppLayout from "./layouts/UnAuthLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import Logout from "./pages/Authentication/Logout";
import BuyProperty from "./pages/BuyProperty";
import Dashboard from "./pages/BuyRequests";
import SellProperty from "./pages/SellProperty";
import SellPropertyRequests from "./pages/SellPropertyRequests";
import SellRequestDetails from "./pages/SellPropertyRequests/SellRequestDetails";
import BuyRequestDetails from "./pages/BuyRequests/BuyRequestDetails";
import Home from "./pages/Authentication/Home";
import OtherServices from "./pages/OtherServices";
import Settings from "./pages/Settings";
import HomeLayout from "./layouts/HomeLayout";
import Renting from "./pages/Renting";
import RentingRequestDetails from "./pages/Renting/RentingRequestDetails";

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route
          path="/"
          element={
            <AppLayout>
              <Home />
            </AppLayout>
          }
        /> */}

        <Route
          path="/app"
          element={
            <HomeLayout>
              <Home />
            </HomeLayout>
          }
        />

<Route
          path="/"
          element={
            <HomeLayout>
              <Home />
            </HomeLayout>
          }
        />

<Route
          path="/services"
          element={
            <AppLayout>
              <OtherServices />
            </AppLayout>
          }
        />

<Route
          path="/settings"
          element={
            <DashboardLayout>
              <Settings />
            </DashboardLayout>
          }
        />

<Route
          path="/signout"
          element={
              <Logout />
          }
        />
<Route
          path="/buy-property"
          element={
            <DashboardLayout>
              <BuyProperty />
            </DashboardLayout>
          }
        />

<Route
          path="/sell-property"
          element={
            <DashboardLayout>
              <SellProperty />
            </DashboardLayout>
          }
        />

<Route
          path="/buy-property-requests"
          element={
            <DashboardLayout>
              <Dashboard />
            </DashboardLayout>
          }
        />

<Route
          path="/sell-property-requests"
          element={
            <DashboardLayout>
              <SellPropertyRequests />
            </DashboardLayout>
          }
        />

<Route
          path="/sell-property-requests/:id"
          element={
            <DashboardLayout>
              <SellRequestDetails />
            </DashboardLayout>
          }
        />

<Route
          path="/buy-property-requests/:id"
          element={
            <DashboardLayout>
              <BuyRequestDetails />
            </DashboardLayout>
          }
        />

<Route
          path="/renting"
          element={
            <DashboardLayout>
              <Renting />
            </DashboardLayout>
          }
        />

<Route
          path="/renting/:id"
          element={
            <DashboardLayout>
              <RentingRequestDetails />
            </DashboardLayout>
          }
        />

      </Routes>
    </BrowserRouter>
  );
}

export default Routing;
